import { useContext, useEffect } from "react"
import styles from "./ErrorBanner.module.css"
import MainContext from "../common/MainContext"
import { AnimatePresence, motion } from "framer-motion"
import { ReactComponent as CloseIcon } from "../assets/images/icons/ic-close.svg"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import Warning3D from "../assets/images/illustrations/3D/warning.png"

const ErrorBanner = ({ error }) => {

    const context = useContext(MainContext)
    const { width } = useWindowDimensions()

    useEffect(() => {
        if (context.error) {
            setTimeout(() => {
                context.setError(null)
            }, 10000)
        }
    }, [context.error])

    const handleClose = (e) => {
        e.stopPropagation();
        context.setError(null)
    }


    return (
        <AnimatePresence>
            {context.error &&
                <motion.div
                    initial={{ x: width > 540 ? '100%' : 0, y: width > 540 ? 0 : '-200%' }}
                    animate={{ x: 0, y: 0 }}
                    exit={{ x: width > 540 ? '100%' : 0, y: width > 540 ? 0 : '-200%' }}
                    transition={{ duration: 0.5, ease: 'easeInOut' }}
                    className={styles.container}>
                    <img src={Warning3D} className={styles.thumbnail} alt="" />
                    <div className={styles.info}>
                        <div className={styles.title}>
                            {error?.title}
                        </div>
                        <div className={styles.body}>
                            {error?.message}
                        </div>
                    </div>
                    <button className={styles.close} onClick={handleClose}>
                        <CloseIcon className={styles.closeIcon} />
                    </button>
                </motion.div>
            }
        </AnimatePresence>
    )
}

export default ErrorBanner
