import { useEffect, useState } from "react"
import { ReactSortable } from "react-sortablejs"
import { Each } from "../common/Each"
import styles from "./RelatedCoursesEditor.module.css"
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg"
import CheckbokSelect from "./CheckboxSelect"
import SimpleCollapsable from "./SimpleCollapsable"
import Button from "./Button"

const RelatedCoursesEditor = ({ selectedCourses, courses, onChange = () => { } }) => {

    const [_selectedcourses, setSelectedCourses] = useState(selectedCourses ?? [])
    const [open, setOpen] = useState(_selectedcourses?.length === 0)

    useEffect(() => {
        onChange(_selectedcourses)
    }, [_selectedcourses])

    return (
        <div className={styles.container}>
            <ReactSortable list={_selectedcourses} setList={setSelectedCourses} draggable=".sortable-info" handle=".handle" dragClass={styles.dragClass}>
                <Each of={_selectedcourses} render={(item, index) => {
                    return (
                        <div className={`${styles.item} sortable-info handle`}>
                            {item.name}
                        </div>
                    )
                }} />
            </ReactSortable>
            <SimpleCollapsable
                expanded={open}
            >
                <div>
                    <CheckbokSelect
                        style={{ border: 0 }}
                        placeholder="Cerca corso"
                        options={
                            courses.map(c => {
                                c._name = c.name
                                c._selected = _selectedcourses.map(sc => sc.id).includes(c.id)
                                return c
                            })}
                        appearance="default"
                        onSelect={(items) => {
                            setSelectedCourses(items)
                        }} />
                </div>
            </SimpleCollapsable>
            <Button fullWidth accentColor="var(--tertiary)"
                disabled={_selectedcourses.length === 0}
                style={{ borderRadius: '0 0 1rem 1rem' }}
                onClick={() => setOpen(o => !o)}>
                {
                    !open &&
                    <>
                        <AddIcon className={styles.addIcon} />
                        AGGIUNGI
                    </>
                }
                {
                    open &&
                    "CHIUDI RICERCA"
                }
            </Button>
        </div>
    )
}

export default RelatedCoursesEditor
