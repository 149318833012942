import { useEffect, useMemo, useState } from "react";
import useContextMenu from "../../common/hooks/useContextMenu";
import useWindowDimensions from "../../common/hooks/useWindowDimensions";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/ic-delete.svg";
import { ReactComponent as EditIcon } from "../../assets/images/icons/ic-pencil.svg";
import Button from "../Button";
import RatingBadge from "../RatingBadge";
import TruncatedText from "../TruncatedText";
import styles from "./ReviewCard.module.css";
import { getInitials, getRandomColor } from "../../utils";
import { useNavigate } from "react-router-dom";
import AlertDialog from "../dialogs/AlertDialog";
import { DialogStatus } from "../../enums";
import api from "../../api";
import MeatBallsMenu from "../MeatBallsMenu";
import Skeleton from "../Skeleton";

const ReviewCard = ({ review, onEdit = () => { }, onDelete = () => { }, loading = false }) => {

    const { width } = useWindowDimensions()
    const { showMenu } = useContextMenu()
    const [openAlert, setOpenAlert] = useState(false)
    const [alertStatus, setAlertStatus] = useState(DialogStatus.Default)

    const menuActions = useMemo(() => (
        [
            { name: "Modifica", icon: <EditIcon className={styles.icon} style={{ color: "var(--sf-light-green)" }} />, onClick: onEdit },
            { name: "Elimina", icon: <DeleteIcon className={styles.icon} style={{ color: "var(--sf-red)" }} />, onClick: () => setOpenAlert(true) }
        ]
    ), [])

    useEffect(() => {
        if (review) {
            review.color = getRandomColor()
        }
    }, [review])

    const deleteReview = async () => {
        setAlertStatus(DialogStatus.Loading)
        try {
            await api.delete(`/admin/reviews/${review.id}`)
            setAlertStatus(DialogStatus.Success)
            onDelete()
        } catch (e) {
            console.error(e)
            setAlertStatus(DialogStatus.Error)
        }
    }

    const onDialogClose = () => {
        setOpenAlert(false)
        setAlertStatus(DialogStatus.Default)
    }

    return (
        <div className={styles.container}
            onContextMenu={e => showMenu(e, menuActions)}
        >
            {
                !loading &&
                <>
                    <div className={styles.userInfo}>
                        {(review.user_avatar || review.user?.picture) &&
                            <img src={review.user_avatar ?? review.user?.picture} alt="" className={styles.avatar} />
                        }
                        {!(review.user_avatar || review.user?.picture) &&
                            <div style={{ backgroundColor: review.color ?? 'var(--primary)' }} className={styles.initials}>{getInitials(review.user_name)}</div>
                        }
                        <div className={styles.info}>
                            <div className={styles.username}>
                                {review.user_name}
                            </div>


                            {width <= 540 &&
                                <RatingBadge rating={review.rating} />
                            }
                            {review.url &&
                                <Button appearance="text" style={{ padding: 0, fontSize: '.75rem', whiteSpace: 'nowrap' }} onClick={() => { window.location.href = review.url }}>VEDI SU {review.platform.toUpperCase()}</Button>
                            }
                        </div>
                    </div>
                    {review.message &&
                        <TruncatedText
                            text={review.message}
                            className={styles.message}
                            maxLength={192}
                        />
                    }
                    {width > 540 &&
                        <RatingBadge rating={review.rating} />
                    }
                    {
                        openAlert &&
                        <AlertDialog
                            open={openAlert}
                            title={"Elimina recensione"}
                            text={`Sei sicuro di voler eliminare questa recensione?`}
                            status={alertStatus}
                            onClose={onDialogClose}
                            actions={[
                                {
                                    label: alertStatus === DialogStatus.Success ? "OK" : "SI, ELIMINA",
                                    onClick: alertStatus === DialogStatus.Success ? onDialogClose : deleteReview,
                                }
                            ]}
                        />
                    }

                    <div className={styles.menu}>
                        <MeatBallsMenu actions={[
                            { label: "Modifica", icon: EditIcon, onClick: onEdit, style: { color: "var(--sf-light-green)" } },
                            { label: "Elimina", icon: DeleteIcon, onClick: () => setOpenAlert(true), style: { color: "var(--sf-red)" } },
                        ]}
                        />
                    </div>
                </>
            }
            {
                loading &&
                <>
                    <div className={styles.userInfo}>
                        <Skeleton type="circle" width="40px" height="40px" />
                        <div className={styles.info}>
                            <div className={styles.username}>
                                <Skeleton type="rect" width="124px" height="16px" borderRadius={"8px"} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.message} style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                        flexGrow: "1",
                        justifyContent: "flex-start",
                        alignItems: "flex-start"
                    }}>
                        <Skeleton type="rect" width="100%" height="14px" borderRadius={"8px"} />
                        <Skeleton type="rect" width="75%" height="14px" borderRadius={"8px"} />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "4px",

                        }}>
                        <Skeleton type="rect" width="24px" height="24px" borderRadius={"4px"} />
                        <Skeleton type="rect" width="24px" height="24px" borderRadius={"4px"} />
                        <Skeleton type="rect" width="24px" height="24px" borderRadius={"4px"} />
                        <Skeleton type="rect" width="24px" height="24px" borderRadius={"4px"} />
                        <Skeleton type="rect" width="24px" height="24px" borderRadius={"4px"} />
                    </div>
                </>
            }
        </div>
    )
}

export default ReviewCard
