import { useEffect, useRef, useState } from "react"
import styles from "./PartnersSettings.module.css"
import TextInput from "../TextInput"
import { useTranslation } from "react-i18next"
import { Each } from "../../common/Each"
import api from "../../api"
import { ReactComponent as InsertDocumentIllustration } from "../../assets/images/illustrations/il-insert-document.svg";
import { ReactComponent as AddIcon } from "../../assets/images/icons/ic-add-circle.svg";
import { ReactComponent as EditIcon } from "../../assets/images/icons/ic-pencil.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/ic-delete.svg";
import Dropzone from "react-dropzone"
import Button from "../Button"
import { ButtonStatus } from "../../common/constants"
import useContextMenu from "../../common/hooks/useContextMenu"
import AlertDialog from "../dialogs/AlertDialog"
import { DialogStatus } from "../../enums"

const PartnersSettings = ({ }) => {
    const { t } = useTranslation()
    const [partners, setPartners] = useState([])
    const [filteredPartners, setFilteredPartners] = useState([])
    const [name, setName] = useState("")
    const [logo, setLogo] = useState(null)
    const [url, setUrl] = useState(null)
    const [partnerPreview, setPartnerPreview] = useState(null)
    const [addStatus, setAddStatus] = useState(null)
    const [addSuccessTimeout, setAddSuccessTimeout] = useState(null)
    const [openAlert, setOpenAlert] = useState(false)
    const [alertStatus, setAlertStatus] = useState(DialogStatus.Default)
    const [partnerToBeDeleted, setPartnerToBeDeleted] = useState(null)
    const dropzoneRef = useRef(null)
    const { showMenu } = useContextMenu()

    useEffect(() => {
        const getPartners = async () => {
            try {
                const partners = await api.get("/admin/partners")
                setPartners(partners)
                setFilteredPartners(partners)
            } catch (e) {
                console.error(e)
            }
        }
        getPartners()
        return () => {
            if (addSuccessTimeout) {
                clearTimeout(addSuccessTimeout)
            }
        }
    }, [])

    const onSearch = (searchText) => {
        if (!searchText) {
            setFilteredPartners(partners)
        } else {
            setFilteredPartners(partners.filter(p => {
                return p.name.toLowerCase().includes(searchText.toLowerCase())
            }))
        }
    }

    const add = async () => {
        setAddStatus(ButtonStatus.Loading)
        try {
            const formData = new FormData()
            formData.append("name", name)
            if (logo) {
                formData.append("logo", logo)
            }

            if (url) {
                formData.append("url", url)
            }

            const partners = await api.post("/admin/partners", formData, {
                headers: { "Content-Type": "multipart/form-data" }
            })
            setPartners(partners)
            setFilteredPartners(partners)
            setAddStatus(ButtonStatus.Success)
            setPartnerPreview(null)
            setLogo(null)
            setUrl(null)
            setName(null)
            setAddSuccessTimeout(
                setTimeout(() => setAddStatus(null), 3000)
            )
        } catch (e) {
            console.error(e)
            setAddStatus(ButtonStatus.Error)
        }
    }

    const deletePartner = async () => {
        if (!partnerToBeDeleted) {
            return;
        }
        setAlertStatus(DialogStatus.Loading)
        try {
            const partners = await api.delete(`/admin/partners/${partnerToBeDeleted.id}`)
            setPartners(partners)
            setFilteredPartners(partners)
            setAlertStatus(DialogStatus.Success)
        } catch (e) {
            console.error(e)
            setAlertStatus(DialogStatus.Error)
        }
    }

    const onDialogClose = () => {
        setOpenAlert(false)
        setAlertStatus(DialogStatus.Default)
        setPartnerToBeDeleted(null)
    }

    return (
        <div className={styles.card}>
            <div className={styles.title}>Partners</div>
            <TextInput
                onKeyUp={onSearch}
                type="search"
                placeholder={t("search")}
                style={{
                    backgroundColor: "var(--background-secondary-color)",
                    overflow: "hidden"

                }} />
            <div className={styles.partners}>
                <Each
                    of={filteredPartners}
                    render={partner => (
                        <div className={styles.partnerCard} onContextMenu={e => {
                            showMenu(e,
                                [
                                    {
                                        name: "Elimina",
                                        icon: <DeleteIcon className={styles.icon} style={{ color: "var(--sf-red)" }} />,
                                        onClick: () => {
                                            setPartnerToBeDeleted(partner)
                                            setOpenAlert(true)
                                        }
                                    }
                                ]
                            )
                        }}>
                            <img className={styles.partnerLogo} alt={`${partner}-logo`} src={partner.logo} />
                            <DeleteIcon className={styles.deleteIcon} onClick={() => {
                                setPartnerToBeDeleted(partner)
                                setOpenAlert(true)
                            }} />
                        </div>
                    )}
                />
            </div>
            <div className={styles.bottom}>
                {
                    !logo && !partnerPreview &&
                    <div className={styles.dropzoneContainer}>
                        <Dropzone
                            accept={{
                                'image/jpeg': [],
                                'image/png': [],
                                'image/webp': [],
                                'image/heic': [],
                                'image/jfif': [],
                            }}
                            multiple={false}
                            onDrop={(files) => {
                                setLogo(files[0])
                                setUrl("")
                                setPartnerPreview(URL.createObjectURL(files[0]))
                            }}>
                            {({ getRootProps, getInputProps }) => (
                                <section style={{ display: 'flex', height: "100%", width: '100%', padding: 0, margin: 0 }}>
                                    <div {...getRootProps()} style={{ display: 'flex', width: '100%' }} ref={dropzoneRef} >
                                        <input {...getInputProps()} />
                                        <div className={styles.dropzone}>
                                            <InsertDocumentIllustration />
                                            <div className={styles.dropzoneLabel}>
                                                Trascina o seleziona l'immagine
                                            </div>
                                            <Button
                                                style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                                accentColor={"var(--tertiary)"}
                                                onClick={() => dropzoneRef.current?.click()}
                                            >
                                                SELEZIONA
                                            </Button>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </div>
                }
                {
                    partnerPreview &&
                    <div className={styles.previewContainer}>
                        <div className={styles.partnerCard}>
                            <img className={styles.partnerLogo} alt={`new-partner-logo`} src={partnerPreview} />
                        </div>
                        <Button
                            onClick={() => {
                                setPartnerPreview(null)
                                setLogo(null)
                                setUrl(null)
                            }}
                            style={{ padding: ".7rem 1rem" }}
                            accentColor={"var(--sf-orange)"}
                        >
                            CAMBIA <EditIcon />
                        </Button>
                    </div>
                }
                <div className={styles.form}>
                    <TextInput
                        type="text"
                        value={name}
                        placeholder="Nome"
                        onKeyUp={(v) => setName(v)} />
                    <TextInput
                        type="text"
                        value={url}
                        placeholder="Url logo"
                        onKeyUp={(v) => {
                            if (v) {
                                setPartnerPreview(v)
                                setLogo(null)
                            }
                            setUrl(v)
                        }} />
                    <Button
                        status={addStatus}
                        disabled={!name || (!logo && !url)}
                        onClick={add}
                        style={{ padding: ".7rem 1rem" }}
                    >
                        AGGIUNGI {addStatus === null && <AddIcon />}
                    </Button>
                </div>


            </div>
            <AlertDialog
                open={openAlert}
                title={"Elimina partner"}
                text={`Sei sicuro di voler eliminare ${partnerToBeDeleted?.name}?`}
                status={alertStatus}
                onClose={onDialogClose}
                actions={[
                    {
                        label: alertStatus === DialogStatus.Success ? "OK" : "SI, ELIMINA",
                        onClick: alertStatus === DialogStatus.Success ? onDialogClose : deletePartner,
                    }
                ]}
            />
        </div>
    )
}

export default PartnersSettings
