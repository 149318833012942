
import { useCallback, useEffect, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import api from "../api"
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg"
import { ReactComponent as EmptyIllustration } from "../assets/images/illustrations/il-empty.svg"
import { Each } from "../common/Each"
import Button from "../components/Button"
import TestCard from "../components/cards/TestCard"
import EmptyContent from "../components/EmptyContent.js"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import Pagination from "../components/Pagination.js"
import TextInput from "../components/TextInput"
import typo from "../typography.module.css"
import { getDefaultTest } from "../utils.js"
import Test from "./Test.js"
import styles from "./Tests.module.css"

const Tests = () => {

    const { t } = useTranslation()
    const [tests, setTests] = useState([]);
    const [loadingPage, setLoadingPage] = useState(true)
    const [page, setPage] = useState(0)
    const [per, setPer] = useState(10)
    const [count, setCount] = useState(null)
    const [showPagination, setShowPagination] = useState(false)
    const [modules, setModules] = useState([])
    const [testsLoading, setTestsLoading] = useState(false)
    const [showAddPage, setShowAddPage] = useState(false)
    const [showEditPage, setShowEditPage] = useState(false)
    const [selectedTest, setSelectedTest] = useState(getDefaultTest())

    useEffect(() => {
        getTests();
    }, [page]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [showAddPage, showEditPage])

    const getTests = async (q = null) => {
        setTestsLoading(true);
        try {
            const data = q ?
                await api.get(`/admin/tests?q=${q}`) :
                await api.get(`/admin/tests?page=${page}&per=${per}`);

            setTests(data.tests);
            setCount(data.count)
            setShowPagination(data.count > per)
        } catch (e) {
            console.error(e);
        }
        if (loadingPage) {
            setLoadingPage(false)
        }
        setTestsLoading(false);
    }

    const onSearch = (searchText) => {
        if (searchText) {
            getTests(searchText)
        } else {
            getTests()
        }
    }

    const onDelete = useCallback(async () => {
        getTests()
    }, [])

    const onComplete = useCallback(() => {
        getTests()
    }, [])

    const onEdit = useCallback(() => {
        setShowEditPage(false)
        getTests()
    }, [tests])

    const onAdd = useCallback(() => {
        setShowAddPage(false)
        getTests()
    }, [tests])

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Test</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        {
                            showAddPage === false && showEditPage === false &&
                            <div className={styles.content}>
                                <div className={styles.header}>
                                    <div className={typo.title}>{t("tests.title")}</div>
                                    <div className={styles.add}>
                                        <Button
                                            style={{
                                                padding: "1rem 2rem",
                                            }}
                                            onClick={() => setShowAddPage(true)}
                                        >
                                            {t("tests.addNewTest").toUpperCase()}
                                            <AddIcon />
                                        </Button>
                                    </div>
                                </div>
                                <TextInput
                                    onKeyUp={onSearch}
                                    type="search"
                                    placeholder={t("search")}
                                    style={{
                                        backgroundColor: "var(--background-secondary-color)",

                                    }} />
                                <div className={styles.tests}>
                                    {
                                        testsLoading === true &&
                                        <Each
                                            of={[1, 2, 3]}
                                            render={() => (
                                                <div className={styles.test}>
                                                    <TestCard loading />
                                                </div>)}
                                        />
                                    }

                                    {
                                        !testsLoading && tests.length > 0 &&
                                        < Each
                                            of={tests.sort((a, b) => {
                                                if (!a.deleted_at && b.deleted_at) {
                                                    return -1
                                                }
                                                return 1
                                            })}
                                            render={(test, testIndex) => (
                                                <div className={styles.test}>
                                                    <TestCard test={test}
                                                        onDelete={onDelete}
                                                        onEdit={() => {
                                                            setSelectedTest(test)
                                                            setShowEditPage(true)
                                                        }}
                                                        onComplete={onComplete} />
                                                </div>
                                            )}
                                        />
                                    }

                                    {
                                        testsLoading === false && tests.length === 0 &&
                                        <EmptyContent
                                            style={{ margin: "0 auto" }}
                                            Illustration={EmptyIllustration}
                                            title={"Nessun test ancora creato"}
                                            message={
                                                <div style={{ display: "flex", alignItems: "center", }}>
                                                    <Button
                                                        appearance="text"
                                                        onClick={() => setShowAddPage(true)}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            gap: ".2rem"
                                                        }}
                                                    >
                                                        <AddIcon />
                                                        Aggiungi
                                                    </Button>
                                                </div>
                                            }
                                        />
                                    }
                                </div>
                            </div>
                        }
                        {
                            showAddPage === true &&
                            <Test onBack={() => setShowAddPage(false)} onSave={onAdd} />
                        }
                        {
                            showEditPage === true &&
                            <Test onBack={() => setShowEditPage(false)} onSave={onEdit} test={selectedTest} />
                        }
                        {
                            showPagination &&
                            <Pagination total={count} per={per} page={page} onChange={p => setPage(p)} loading={loadingPage} />
                        }
                    </div>
                </div>
            </div>

        </HeaderFooterLayout>
    )
}

export default Tests
