
import { useEffect, useMemo, useRef, useState } from "react"
import { ChromePicker } from "react-color"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import api from "../../api"
import { ReactComponent as AddIcon } from "../../assets/images/icons/ic-add-circle.svg"
import { ReactComponent as CloseIcon } from "../../assets/images/icons/ic-close.svg"
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/ic-delete.svg"
import { ReactComponent as EditIcon } from "../../assets/images/icons/ic-pencil.svg"
import { ButtonStatus, TagType } from "../../common/constants"
import { Each } from "../../common/Each"
import useClickOutside from "../../common/hooks/useClickOutside"
import Button from "../../components/Button"
import IconsDialog from "../../components/dialogs/IconsDialog"
import Select from "../../components/Select"
import Tag from "../../components/Tag"
import TextInput from "../../components/TextInput"
import styles from "./TagsSettings.module.css"

const TagsSettings = ({ }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [tags, setTags] = useState([])
    const [filteredTags, setFilteredTags] = useState([])
    const [tagId, setTagId] = useState(null)
    const [name, setName] = useState("")
    const [color, setColor] = useState("#000000")
    const [type, setType] = useState(TagType.General)
    const [icon, setIcon] = useState(null)
    const [iconColor, setIconColor] = useState("#2a2a2a99")
    const [openIconPicker, setOpenIconPicker] = useState(false)
    const [openColorPicker, setOpenColorPicker] = useState(false)
    const [openIconColorPicker, setOpenIconColorPicker] = useState(false)
    const [savingTag, setSavingTag] = useState(null)
    const [deletingTag, setDeletingTag] = useState(null)
    const [tagSavingTimeout, setTagSavingTimeout] = useState(null)

    const colorRef = useRef(null)
    const iconColorRef = useRef(null)

    useClickOutside(colorRef, () => setOpenColorPicker(false))
    useClickOutside(iconColorRef, () => setOpenIconColorPicker(false))

    const typeOptions = useMemo(() => {
        return [
            { id: 0, name: "Generico", value: TagType.General },
            { id: 1, name: "Feature", value: TagType.Feature }
        ]
    }, [])

    useEffect(() => {
        getTags()

        return () => {
            if (tagSavingTimeout) {
                clearTimeout(tagSavingTimeout)
            }
        }
    }, [])

    useEffect(() => {
        if (type === TagType.General) {
            setIcon(null)
        }
    }, [type])

    useEffect(() => {
        if (savingTag === ButtonStatus.Success || savingTag === ButtonStatus.Error) {
            setTagSavingTimeout(setTimeout(() => setSavingTag(null), 3000))
        }
    }, [savingTag])

    const tagIcon = useMemo(() => {
        if (!icon)
            return null

        if (icon.startsWith("<svg")) {
            return () => <div
                style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "20px", height: "20px" }}
                dangerouslySetInnerHTML={{ __html: icon.replace(/fill="#[^"]*"/g, `fill="${iconColor}"`).replace(/stroke="#[^"]*"/g, 'stroke="currentColor"') }} />
        }
        return () => <img src={icon} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} />
    }, [icon, iconColor])

    const getTags = async () => {
        try {
            const tags = await api.get("/admin/tags?type=all")
            setTags(tags)
            setFilteredTags(tags)
        } catch (e) {
            console.error(e)
        }
    }

    const saveTag = async () => {
        setSavingTag(ButtonStatus.Loading)
        try {
            const data = {
                name,
                icon: icon?.replace(/fill="#[^"]*"/g, `fill="${iconColor}"`).replace(/stroke="#[^"]*"/g, 'stroke="currentColor"'),
                color,
                type,
            }
            if (tagId) {
                await api.put(`/admin/tags/${tagId}`, data)
            } else {
                await api.post("/admin/tags", data)
            }

            getTags()
            resetEdit()
            setSavingTag(ButtonStatus.Success)
        } catch (e) {
            console.error(e)
            setSavingTag(ButtonStatus.Error)
        }
    }

    const deleteTag = async () => {
        setDeletingTag(ButtonStatus.Loading)
        try {
            await api.delete(`/admin/tags/${tagId}`)
            resetEdit()
            setDeletingTag(ButtonStatus.Success)
        } catch (e) {
            console.error(e)
            setDeletingTag(ButtonStatus.Error)
        }
    }

    const resetEdit = () => {
        setTagId(null)
        setType(TagType.General)
        setName("")
        setColor("#000000")
        setIcon(null)
    }

    const onSearch = (searchText) => {
        if (!searchText) {
            setFilteredTags(tags)
        } else {
            setFilteredTags(tags.filter(t => {
                return t.name.toLowerCase().includes(searchText.toLowerCase())
            }))
        }
    }

    return (
        <div className={styles.card}>
            <div className={styles.tags}>
                <div className={styles.title}>Tag</div>
                <TextInput
                    onKeyUp={onSearch}
                    type="search"
                    placeholder={t("search")}
                    style={{
                        backgroundColor: "var(--background-secondary-color)",
                        overflow: "hidden"

                    }} />
                <div className={styles.tagsContent}>
                    <div className={styles.tagsType}>Generici</div>
                    <div className={styles.tagsList}>
                        <Each
                            of={filteredTags.filter(t => t.type === TagType.General).sort((a, b) => {
                                return a.name > b.name
                            })}
                            render={(tag) => (
                                <Tag tag={tag} onClick={() => {
                                    if (tagId === tag.id) {
                                        resetEdit()
                                    } else {
                                        setTagId(tag.id)
                                        setName(tag.name)
                                        setColor(tag.color)
                                        setIcon(null)
                                        setType(tag.type)
                                    }
                                }} />
                            )}
                        />
                    </div>
                </div>

                <div className={styles.tagsContent}>
                    <div className={styles.tagsType}>Feature</div>
                    <div className={styles.tagsList}>
                        <Each
                            of={filteredTags.filter(t => t.type === TagType.Feature)}
                            render={(tag) => (
                                <Tag tag={tag} onClick={() => {

                                    if (tagId === tag.id) {
                                        resetEdit()
                                    } else {
                                        setTagId(tag.id)
                                        setName(tag.name)
                                        setIcon(tag.icon)
                                        setType(tag.type)
                                        setColor("#000000")
                                    }
                                }} />
                            )}
                        />
                    </div>
                </div>
                <div className={styles.tagsContent} style={{ overflow: "visible" }}>
                    <div className={styles.tagsForm}>
                        <div className={styles.row}>
                            <Tag tag={{
                                name,
                                type,
                                color: color,
                            }}
                                Icon={tagIcon}
                            />
                        </div>
                        <div className={styles.row}>
                            <div style={{ flexBasis: "50%" }}>
                                <Select
                                    selected={typeOptions.find(t => t.value === type)}
                                    options={typeOptions}
                                    onSelect={(tagType) => setType(tagType.value)}
                                />

                            </div>
                            <div style={{ flexBasis: "50%" }}>
                                <TextInput
                                    type="text"
                                    value={name}
                                    placeholder="Nome"
                                    onKeyUp={(v) => setName(v)} />
                            </div>
                            {
                                type === TagType.General &&
                                <div
                                    onClick={() => setOpenColorPicker((p) => !p)}
                                    className={styles.tagBackground}
                                    style={{ backgroundColor: color }}>
                                    <div ref={colorRef} className={openColorPicker ? `${styles.colorPicker} ${styles.open}` : styles.colorPicker} onClick={(e) => e.stopPropagation()}>
                                        <ChromePicker disableAlpha color={color} onChange={(color) => setColor(color.hex)} />
                                    </div>
                                </div>
                            }

                            {
                                !icon && type === TagType.Feature &&
                                <div className={styles.addIcon}>
                                    <Button
                                        appearance="text"
                                        style={{ display: "flex", gap: ".5rem", alignItems: "center", flexDirection: "row" }}
                                        onClick={() => setOpenIconPicker(true)}
                                    >
                                        Aggiungi icona <AddIcon />
                                    </Button>
                                </div>
                            }
                        </div>

                        {
                            icon && type === TagType.Feature &&
                            <div className={styles.row} style={{ justifyContent: "center" }}>
                                <div className={styles.iconContainer}>
                                    <div className={styles.icon}>
                                        {
                                            icon.startsWith("<svg") &&
                                            <div className={styles.iconData}
                                                dangerouslySetInnerHTML={{ __html: icon.replace(/fill="#[^"]*"/g, `fill="${iconColor}"`).replace(/stroke="#[^"]*"/g, 'stroke="currentColor"') }}
                                            />
                                        }
                                        {
                                            !icon.startsWith("<svg") &&
                                            <img src={icon} className={styles.iconData} alt="" />
                                        }
                                        <div className={styles.iconActions}>
                                            <CloseIcon
                                                style={{ width: "24px", height: "24px", color: "var(--secondary)" }}
                                                onClick={() => setIcon(null)}
                                            />
                                            <EditIcon style={{ color: "var(--primary)" }} onClick={() => setOpenIconPicker(true)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={styles.tagActions}>
                        {
                            tagId &&
                            <>
                                <Button
                                    inverse
                                    accentColor={"var(--sf-yellow)"}
                                    onClick={resetEdit}
                                    disabled={!tagId}
                                    style={{ padding: "0.7rem 1rem" }}
                                >
                                    ANNULLA <CloseIcon style={{ width: "16px", height: "16px" }} />
                                </Button>

                                <Button
                                    inverse
                                    status={deletingTag}
                                    accentColor={"var(--sf-red)"}
                                    onClick={deleteTag}
                                    disabled={!tagId}
                                    style={{ padding: "0.7rem 1rem" }}
                                >
                                    ELIMINA {!deletingTag && <DeleteIcon style={{ width: "16px", height: "16px" }} />}
                                </Button>
                            </>
                        }
                        <Button
                            status={savingTag}
                            accentColor={"var(--tertiary)"}
                            onClick={saveTag}
                            disabled={!name}
                            style={{ padding: "0.7rem 1rem" }}
                        >
                            {tagId === null ? <>AGGIUNGI {!savingTag && <AddIcon />}</> : <>MODIFICA {!savingTag && <EditIcon />}</>}
                        </Button>
                    </div>
                </div>
            </div>

            <IconsDialog
                open={openIconPicker}
                selected={icon}
                onClose={() => setOpenIconPicker(false)}
                onSelect={(icon) => {
                    setIcon(icon.data)
                    setOpenIconPicker(false)
                }} />
        </div>
    )
}

export default TagsSettings
