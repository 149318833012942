import { useMemo } from "react"
import styles from "./Pagination.module.css"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import { ReactComponent as EllipsisIcon } from "../assets/images/icons/ic-ellipsis.svg"
import { Each } from "../common/Each"
import Skeleton from "./Skeleton"

const PageButton = ({ value, page, onClick = () => { } }) => {
  return (
    <div
      className={value === page ? `${styles.page} ${styles.selected}` : styles.page}
      onClick={() => onClick(value)}
    >
      {value + 1}
    </div >
  )
}

const Pagination = ({ page = 5, total, per, group = 5, onChange = () => { }, loading = false }) => {

  const pages = useMemo(() => {
    return Math.ceil(total / per)
  }, [total, per])

  const previousDisabled = useMemo(() => (page === 0 || loading), [page, loading])
  const nextDisabled = useMemo(() => (page === pages - 1 || loading), [pages, page, loading])

  return (
    <div className={styles.container}>
      {
        total > 0 &&
        <div className={(page === 0 || loading) ? `${styles.arrow} ${styles.disabled}` : styles.arrow} onClick={() => {
          if (!previousDisabled) {
            onChange(page - 1)
          }
        }}>
          <ChevronIcon className={styles.back} />
        </div>
      }
      {
        !loading &&
        <div className={styles.pages}>
          <Each
            of={Array(pages).fill("")}
            render={(e, i) => {
              if (page < group - 1) {
                if (i === group) {
                  return (
                    <div className={styles.pages}>
                      <EllipsisIcon className={styles.ellipsis} />
                      <PageButton onClick={onChange} value={pages - 1} page={page} />
                    </div>
                  )
                } else if (i < group) {
                  return <PageButton onClick={onChange} value={i} page={page} />
                }
              } else {
                if (i === 0) {
                  return (
                    <div className={styles.pages}>
                      <PageButton onClick={onChange} value={i} page={page} />
                      <EllipsisIcon className={styles.ellipsis} />
                    </div>
                  )
                } else if (i > (page - group / 2) && i < (page + group / 2)) {
                  return <PageButton onClick={onChange} value={i} page={page} />
                } else if (i === pages - 1) {
                  return <div className={styles.pages}>
                    <EllipsisIcon className={styles.ellipsis} />
                    <PageButton onClick={onChange} value={pages - 1} page={page} />
                  </div>
                }
              }
            }}
          />
        </div>
      }
      {
        loading &&
        <div className={styles.pages}>
          <Each
            of={[0, 1, 2, 3]}
            render={() => (
              <Skeleton type="circle" width={"48px"} height={"48px"} />
            )}
          />
        </div>
      }
      {
        total > 0 &&
        <div className={(page === pages - 1 || loading) ? `${styles.arrow} ${styles.disabled}` : styles.arrow} onClick={() => {
          if (!nextDisabled) {
            onChange(page + 1)
          }
        }}>
          <ChevronIcon className={styles.forward} />
        </div>
      }
    </div>
  )
}

export default Pagination
