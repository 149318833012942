import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import api from "../../api";
import { ReactComponent as ArticleIconActive } from "../../assets/images/icons/ic-article-active.svg";
import { ReactComponent as ArticleIcon } from "../../assets/images/icons/ic-article.svg";
import { ReactComponent as BookIconActive } from "../../assets/images/icons/ic-book-active.svg";
import { ReactComponent as BookIcon } from "../../assets/images/icons/ic-book-inactive.svg";
import { ReactComponent as CareersIconActive } from "../../assets/images/icons/ic-careers-active.svg";
import { ReactComponent as CareersIcon } from "../../assets/images/icons/ic-careers.svg";
import { ReactComponent as CartIcon } from "../../assets/images/icons/ic-cart-inactive.svg";
import { ReactComponent as CartIconActive } from "../../assets/images/icons/ic-cart.svg";
import { ReactComponent as ChatIconActive } from "../../assets/images/icons/ic-chat-active.svg";
import { ReactComponent as ChatIcon } from "../../assets/images/icons/ic-chat.svg";
import { ReactComponent as ChevronIcon } from "../../assets/images/icons/ic-chevron.svg";
import { ReactComponent as CommunityIconActive } from "../../assets/images/icons/ic-community-active.svg";
import { ReactComponent as CommunityIcon } from "../../assets/images/icons/ic-community.svg";
import { ReactComponent as ContentIconActive } from "../../assets/images/icons/ic-content-active.svg";
import { ReactComponent as ContentIcon } from "../../assets/images/icons/ic-content.svg";
import { ReactComponent as DashboardIconActive } from "../../assets/images/icons/ic-dashboard-active.svg";
import { ReactComponent as DashboardIcon } from "../../assets/images/icons/ic-dashboard-inactive.svg";
import { ReactComponent as ExerciseIconActive } from "../../assets/images/icons/ic-exercise-active.svg";
import { ReactComponent as ExerciseIcon } from "../../assets/images/icons/ic-exercise-inactive.svg";
import { ReactComponent as MaterialsIconActive } from "../../assets/images/icons/ic-files-active.svg";
import { ReactComponent as MaterialsIcon } from "../../assets/images/icons/ic-files-inactive.svg";
import { ReactComponent as MacroIconActive } from "../../assets/images/icons/ic-macro-active.svg";
import { ReactComponent as MacroIcon } from "../../assets/images/icons/ic-macro-inactive.svg";
import { ReactComponent as MinimizeIcon } from "../../assets/images/icons/ic-minimize.svg";
import { ReactComponent as BellFillIcon } from "../../assets/images/icons/ic-notification-active.svg";
import { ReactComponent as BellIcon } from "../../assets/images/icons/ic-notification.svg";
import { ReactComponent as PartnerIconActive } from "../../assets/images/icons/ic-partner-active.svg";
import { ReactComponent as PartnerIcon } from "../../assets/images/icons/ic-partner.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/icons/ic-settings-inactive.svg";
import { ReactComponent as SettingsIconActive } from "../../assets/images/icons/ic-settings.svg";
import { ReactComponent as ShopIconActive } from "../../assets/images/icons/ic-shop-active.svg";
import { ReactComponent as ShopIcon } from "../../assets/images/icons/ic-shop.svg";
import { ReactComponent as StatsActiveIcon } from "../../assets/images/icons/ic-stats-active.svg";
import { ReactComponent as StatsIcon } from "../../assets/images/icons/ic-stats.svg";
import { ReactComponent as TeachingIconActive } from "../../assets/images/icons/ic-teaching-active.svg";
import { ReactComponent as TeachingIcon } from "../../assets/images/icons/ic-teaching.svg";
import { ReactComponent as TestIconActive } from "../../assets/images/icons/ic-test-active.svg";
import { ReactComponent as TestIcon } from "../../assets/images/icons/ic-test-inactive.svg";
import { ReactComponent as ReviewIconActive } from "../../assets/images/icons/ic-thumb-up-fill.svg";
import { ReactComponent as ReviewIcon } from "../../assets/images/icons/ic-thumb-up.svg";
import { ReactComponent as VideoIconActive } from "../../assets/images/icons/ic-video-active.svg";
import { ReactComponent as BookingsActiveIcon } from "../../assets/images/icons/ic-video-call-active.svg";
import { ReactComponent as BookingsIcon } from "../../assets/images/icons/ic-video-call.svg";
import { ReactComponent as VideoIcon } from "../../assets/images/icons/ic-video.svg";
import UserPlaceholder from "../../assets/images/placeholders/user-placeholder.png";
import { ReactComponent as Logo } from "../../assets/images/velv-black.svg";
import { ReactComponent as LogoMini } from "../../assets/images/velv-minimized.svg";
import MainContext from "../../common/MainContext";
import useWindowDimensions from "../../common/hooks/useWindowDimensions";
import SimpleCollapsable from '../../components/SimpleCollapsable';
import Skeleton from "../Skeleton";
import Hamburger from "./Hamburger";
import styles from "./SideNav.module.css";

const SideNav = ({ onChange }) => {

    const { t } = useTranslation()
    const { width } = useWindowDimensions()
    const location = useLocation()
    const context = useContext(MainContext)

    const [expanded, setExpanded] = useState({
        content: false,
        teaching: false,
        shop: false,
    })

    const [active, setActive] = useState({
        content: false,
        teaching: false,
        shop: false,
    })

    const navSections = useMemo(() => {
        return {
            "content": ["macros", "careers", "videos", "articles", "reviews"],
            "teaching": ["courses", "exercises", "materials", "tests"],
            "shop": ["shop_courses", "orders"]
        }
    }, [])

    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState(width > 767)
    const noSideNavRoutes = useMemo(() => { return ["/login", "/macroPreview", "/changePassword"] }, [])

    useEffect(() => {
        if (context.scrollRef?.current) {
            context.scrollRef?.current.scrollIntoView()
        }
    }, [location])

    useEffect(() => {
        if (location.pathname !== "/changePassword" && context?.user?.force_change_password) {
            navigate("/changePassword")
        }
        for (const navSection in navSections) {
            let isActive = false
            for (const s of navSections[navSection]) {
                const rootPathname = location.pathname.split("/")[1]
                if (rootPathname === s) {
                    isActive = true
                    break;
                }
            }
            setExpanded((e) => ({ ...e, [navSection]: isActive }))
            setActive((a) => ({ ...a, [navSection]: isActive }))
        }
    }, [location, context, navigate])

    useEffect(() => {
        return () => {
            document.body.style.overflow = '';
        }
    }, [])

    useEffect(() => {
        setIsOpen(width > 1120)
    }, [width])

    useEffect(() => {
        if (isOpen && width <= 768) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = '';
        }
    }, [isOpen, width])

    useEffect(() => {
        async function getUser() {
            try {
                let user = await api.get("/admin/")
                if (user) {
                    context.setUser(user)
                }
            }
            catch (error) {
                console.error(error)
            }
        }
        if (!context.user) {
            getUser()
        }
    }, [context])

    function toggleOpen() {
        setIsOpen(!isOpen)

        if (onChange) {
            onChange()
        }
    }

    function closeSideNav() {
        if (width <= 768) {
            setIsOpen(false)
        }
    }

    function getIcon(page) {
        if (page === "" && location.pathname === "/") {
            return <DashboardIconActive style={{ width: '20px', height: '20px' }}></DashboardIconActive>
        } else if (page === "") {
            return <DashboardIcon style={{ width: '20px', height: '20px' }}></DashboardIcon>
        }

        switch (page) {
            case "content": return active.content ?
                <ContentIconActive style={{ width: '20px', height: '20px' }} /> :
                <ContentIcon style={{ width: '20px', height: '20px' }} />
            case "shop": return active.shop ?
                <ShopIconActive style={{ width: '20px', height: '20px' }} /> :
                <ShopIcon style={{ width: '20px', height: '20px' }} />
            case "teaching": return active.teaching ?
                <TeachingIconActive style={{ width: '20px', height: '20px' }} /> :
                <TeachingIcon style={{ width: '20px', height: '20px' }} />
            default: break;
        }

        const isActive = location.pathname && location.pathname.includes(`/${page}`)
        switch (page) {
            case 'macros':
                return isActive ?
                    <MacroIconActive style={{ width: '20px', height: '20px' }} /> :
                    <MacroIcon style={{ width: '20px', height: '20px' }} />
            case 'careers':
                return isActive ?
                    <CareersIconActive style={{ width: '20px', height: '20px' }} /> :
                    <CareersIcon style={{ width: '20px', height: '20px' }} />
            case 'courses':
                return isActive ?
                    <BookIconActive style={{ width: '20px', height: '20px' }} /> :
                    <BookIcon style={{ width: '20px', height: '20px' }} />
            case 'shop_courses':
                return isActive ?
                    <BookIconActive style={{ width: '20px', height: '20px' }} /> :
                    <BookIcon style={{ width: '20px', height: '20px' }} />
            case 'users':
                return isActive ?
                    <CommunityIconActive style={{ width: '20px', height: '20px' }} /> :
                    <CommunityIcon style={{ width: '20px', height: '20px' }} />
            case 'settings':
                return isActive ?
                    <SettingsIconActive style={{ width: '20px', height: '20px' }} /> :
                    <SettingsIcon style={{ width: '20px', height: '20px' }} />
            case 'orders':
                return isActive ?
                    <CartIconActive style={{ width: '20px', height: '20px' }} /> :
                    <CartIcon style={{ width: '20px', height: '20px' }} />
            case 'videos':
                return isActive ?
                    <VideoIconActive style={{ width: '20px', height: '20px' }} /> :
                    <VideoIcon style={{ width: '20px', height: '20px' }} />
            case 'articles':
                return isActive ?
                    <ArticleIconActive style={{ width: '20px', height: '20px' }} /> :
                    <ArticleIcon style={{ width: '20px', height: '20px' }} />
            case 'reviews':
                return isActive ?
                    <ReviewIconActive style={{ width: '20px', height: '20px' }} /> :
                    <ReviewIcon style={{ width: '20px', height: '20px' }} />
            case 'bookings':
                return isActive ?
                    <BookingsActiveIcon style={{ width: '20px', height: '20px' }} /> :
                    <BookingsIcon style={{ width: '20px', height: '20px' }} />
            case 'reports':
                return isActive ?
                    <StatsActiveIcon style={{ width: '20px', height: '20px' }} /> :
                    <StatsIcon style={{ width: '20px', height: '20px' }} />
            case 'exercises':
                return isActive ?
                    <ExerciseIconActive style={{ width: '20px', height: '20px' }} /> :
                    <ExerciseIcon style={{ width: '20px', height: '20px' }} />
            case 'tests':
                return isActive ?
                    <TestIconActive style={{ width: '20px', height: '20px' }} /> :
                    <TestIcon style={{ width: '20px', height: '20px' }} />
            case 'materials':
                return isActive ?
                    <MaterialsIconActive style={{ width: '20px', height: '20px' }} /> :
                    <MaterialsIcon style={{ width: '20px', height: '20px' }} />
            case 'messages':
                return isActive ?
                    <ChatIconActive style={{ width: '20px', height: '20px' }} /> :
                    <ChatIcon style={{ width: '20px', height: '20px' }} />
            default: return
        }
    }

    function navigateTo(page) {
        if (location.pathname.includes("/lessons/")) {
            window.location.href = page
        }
        else {
            navigate(page)
        }
    }

    return (
        <>
            {
                noSideNavRoutes.filter(s => location.pathname.includes(s)).length === 0 && (
                    <div className={styles.sidenav + ' ' + (isOpen ? styles.open : styles.close)} id="sidenav">
                        <div className={styles.header}>

                            {width > 768 && !isOpen &&
                                <LogoMini className={styles.logoMini}></LogoMini>
                            }

                            {(isOpen || width <= 768) &&
                                <>
                                    <Logo className={styles.logo}></Logo>
                                    <div className={styles.spacer}></div>
                                </>
                            }

                            {width <= 768 &&
                                <>
                                    <div className={styles.iconButtons}>
                                        <button className={styles.iconButton} onClick={() => {
                                            closeSideNav()
                                            navigate("/notifications")
                                        }}>
                                            {context.notifications?.metadata.unread === 0 &&
                                                <BellIcon />
                                            }
                                            {context.notifications?.metadata.unread > 0 &&
                                                <>
                                                    <BellFillIcon />
                                                    <div className={styles.badge}>
                                                        {context.notifications.metadata.unread}
                                                    </div>
                                                </>
                                            }
                                        </button>
                                    </div>
                                    <div className={styles.hamburger}>
                                        <Hamburger isOpen={isOpen} onChange={(event) => { setIsOpen(event.target.checked) }}></Hamburger>
                                    </div>
                                </>
                            }

                            {width > 768 &&
                                <button className={styles.closeButton} onClick={toggleOpen} aria-label="close-sidebar">
                                    <MinimizeIcon></MinimizeIcon>
                                </button>
                            }
                        </div>
                        <div className={`${styles.content}`}>
                            <button className={[styles.navLink, location.pathname === '/' ? styles.active : ''].join(' ')}
                                onClick={() => {
                                    closeSideNav()
                                    navigateTo('/')
                                }}>
                                {getIcon('')}
                                {isOpen &&
                                    <>
                                        Dashboard
                                    </>
                                }
                            </button>
                            <button className={[styles.navLink, location.pathname.includes('/users') ? styles.active : ''].join(' ')}
                                onClick={() => {
                                    closeSideNav()
                                    navigate('/users')
                                }}>
                                {getIcon('users')}
                                {isOpen &&
                                    <>
                                        {t('sidenav.users')}
                                    </>
                                }
                            </button>
                            <div className={styles.navSection}>
                                <button onClick={() => setExpanded(e => ({ ...e, content: !e.content }))} className={[styles.navLink, active.content ? styles.sectionActive : ""].join(" ")}>
                                    {getIcon("content")}
                                    {isOpen &&
                                        <>
                                            {t("sidenav.content")}
                                            <div className={styles.chevron}>
                                                <ChevronIcon
                                                    style={{
                                                        transform: !expanded.content ? "rotate(180deg)" : "none",
                                                    }} />
                                            </div>
                                        </>
                                    }
                                </button>
                                <SimpleCollapsable expanded={expanded.content}>
                                    <div className={styles.subNavLinks}>
                                        <button className={[styles.navLink, location.pathname.includes('/macros') ? styles.active : ''].join(' ')}
                                            onClick={() => {
                                                closeSideNav()
                                                navigateTo('/macros')
                                            }}>
                                            {getIcon('macros')}
                                            {isOpen &&
                                                <>
                                                    {t('sidenav.macros')}
                                                </>
                                            }
                                        </button>
                                        <button className={[styles.navLink, location.pathname.includes('/careers') ? styles.active : ''].join(' ')}
                                            onClick={() => {
                                                closeSideNav()
                                                navigateTo('/careers')
                                            }}>
                                            {getIcon('careers')}
                                            {isOpen &&
                                                <>
                                                    {t('sidenav.careers')}
                                                </>
                                            }
                                        </button>
                                        <button className={[styles.navLink, location.pathname.includes('/videos') ? styles.active : ''].join(' ')}
                                            onClick={() => {
                                                closeSideNav()
                                                navigate('/videos')
                                            }}>
                                            {getIcon('videos')}
                                            {isOpen &&
                                                <>
                                                    {t('sidenav.videos')}
                                                </>
                                            }
                                        </button>
                                        <button className={[styles.navLink, location.pathname.includes('/articles') ? styles.active : ''].join(' ')}
                                            onClick={() => {
                                                closeSideNav()
                                                navigate('/articles')
                                            }}>
                                            {getIcon('articles')}
                                            {isOpen &&
                                                <>
                                                    {t('sidenav.articles')}
                                                </>
                                            }
                                        </button>
                                        <button className={[styles.navLink, location.pathname.includes('/reviews') ? styles.active : ''].join(' ')}
                                            onClick={() => {
                                                closeSideNav()
                                                navigate('/reviews')
                                            }}>
                                            {getIcon('reviews')}
                                            {isOpen &&
                                                <>
                                                    {t('sidenav.reviews')}
                                                </>
                                            }
                                        </button>
                                    </div>
                                </SimpleCollapsable>
                            </div>
                            <div className={styles.navSection}>
                                <button onClick={() => setExpanded(e => ({ ...e, teaching: !e.teaching }))} className={[styles.navLink, active.teaching ? styles.sectionActive : ""].join(" ")}>
                                    {getIcon("teaching")}
                                    {isOpen &&
                                        <>
                                            {t("sidenav.teaching")}
                                            <div className={styles.chevron}>
                                                <ChevronIcon
                                                    style={{
                                                        transform: !expanded.teaching ? "rotate(180deg)" : "none",
                                                    }} />
                                            </div>
                                        </>
                                    }
                                </button>
                                <SimpleCollapsable expanded={expanded.teaching}>
                                    <div className={styles.subNavLinks}>
                                        <button className={[styles.navLink, location.pathname.includes('/courses') ? styles.active : ''].join(' ')}
                                            onClick={() => {
                                                closeSideNav()
                                                navigate('/courses')
                                            }}>
                                            {getIcon('courses')}
                                            {isOpen &&
                                                <>
                                                    {t('sidenav.courses')}
                                                </>
                                            }
                                        </button>
                                        <button className={[styles.navLink, location.pathname.includes('/exercises') ? styles.active : ''].join(' ')}
                                            onClick={() => {
                                                closeSideNav()
                                                navigate('/exercises')
                                            }}>
                                            {getIcon('exercises')}
                                            {isOpen &&
                                                <>
                                                    {t('sidenav.exercises')}
                                                </>
                                            }
                                        </button>
                                        <button className={[styles.navLink, location.pathname.includes('/tests') ? styles.active : ''].join(' ')}
                                            onClick={() => {
                                                closeSideNav()
                                                navigate('/tests')
                                            }}>
                                            {getIcon('tests')}
                                            {isOpen &&
                                                <>
                                                    {t('sidenav.tests')}
                                                </>
                                            }
                                        </button>
                                        <button className={[styles.navLink, location.pathname.includes('/materials') ? styles.active : ''].join(' ')}
                                            onClick={() => {
                                                closeSideNav()
                                                navigate('/materials')
                                            }}>
                                            {getIcon('materials')}
                                            {isOpen &&
                                                <>
                                                    {t('sidenav.materials')}
                                                </>
                                            }
                                        </button>
                                    </div>
                                </SimpleCollapsable>
                            </div>
                            <div className={styles.navSection}>
                                <button onClick={() => setExpanded(e => ({ ...e, shop: !e.shop }))} className={[styles.navLink, active.shop ? styles.sectionActive : ""].join(" ")}>
                                    {getIcon("shop")}
                                    {isOpen &&
                                        <>
                                            {t("sidenav.shop")}
                                            <div className={styles.chevron}>
                                                <ChevronIcon
                                                    style={{
                                                        transform: !expanded.shop ? "rotate(180deg)" : "none",
                                                    }} />
                                            </div>
                                        </>
                                    }
                                </button>
                                <SimpleCollapsable expanded={expanded.shop}>
                                    <div className={styles.subNavLinks}>
                                        <button className={[styles.navLink, location.pathname.includes('/shop_courses') ? styles.active : ''].join(' ')}
                                            onClick={() => {
                                                closeSideNav()
                                                navigateTo('/shop_courses')
                                            }}>
                                            {getIcon('shop_courses')}
                                            {isOpen &&
                                                <>
                                                    {t('sidenav.courses')}
                                                </>
                                            }
                                        </button>

                                        <button className={[styles.navLink, location.pathname.includes('/orders') ? styles.active : ''].join(' ')}
                                            onClick={() => {
                                                closeSideNav()
                                                navigate('/orders')
                                            }}>
                                            {getIcon('orders')}
                                            {isOpen &&
                                                <>
                                                    {t('sidenav.orders')}
                                                </>
                                            }
                                        </button>
                                    </div>
                                </SimpleCollapsable>
                            </div>
                            <button className={[styles.navLink, location.pathname.includes('/bookings') ? styles.active : ''].join(' ')}
                                onClick={() => {
                                    closeSideNav()
                                    navigate('/bookings')
                                }}>
                                {getIcon('bookings')}
                                {isOpen &&
                                    <>
                                        {t('sidenav.bookings')}
                                    </>
                                }
                            </button>
                            <button className={[styles.navLink, location.pathname.includes('/reports') ? styles.active : ''].join(' ')}
                                onClick={() => {
                                    closeSideNav()
                                    navigate('/reports')
                                }}>
                                {getIcon('reports')}
                                {isOpen &&
                                    <>
                                        {t('sidenav.reports')}
                                    </>
                                }
                            </button>
                            <button className={[styles.navLink, location.pathname.includes('/settings') ? styles.active : ''].join(' ')}
                                onClick={() => {
                                    closeSideNav()
                                    navigate('/settings')
                                }}>
                                {getIcon('settings')}
                                {isOpen &&
                                    <>
                                        {t('sidenav.settings')}
                                    </>
                                }
                            </button>
                        </div>
                        {/* <div className={styles.spacer}></div> */}
                        <div className={styles.bottom}>
                            <button
                                className={[styles.bottomNavLink, location.pathname.includes('/messages') ? styles.active : ''].join(' ')}
                                onClick={() => {
                                    closeSideNav()
                                    navigateTo('/messages')
                                }}>
                                {getIcon('messages', context.un)}
                                {context.unreadMessages > 0 &&
                                    <div className={styles.iconBadge}>{context.unreadMessages}</div>
                                }
                                {isOpen &&
                                    <>
                                        {t('sidenav.messages')}
                                    </>
                                }
                            </button>
                            <button className={styles.userButton} onClick={() => {
                                closeSideNav()
                                navigateTo("/profile")
                            }}>
                                <div className={styles.userContainer}>
                                    {
                                        context.user &&
                                        <>

                                            <img src={context.user.picture ?? UserPlaceholder} alt="avatar" className={styles.userAvatar} />
                                            <div className={styles.userName}>{`${context.user.name} ${context.user.surname}`}</div>
                                        </>
                                    }
                                    {
                                        !context.user &&
                                        <>
                                            <Skeleton type="circle" width="32px" height="32px" />
                                            <Skeleton type="rect" width="144px" height="1rem" borderRadius="12px" />
                                        </>
                                    }
                                </div>
                            </button>
                        </div>
                    </div>
                )
            }
        </>

    )

}

export default SideNav
