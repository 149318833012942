import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/ic-arrow.svg";
import { Each } from "../../common/Each";
import HeaderFooterLayout from "../../components/layouts/HeaderFooterLayout";
import typo from "../../typography.module.css";
import Button from "../Button";
import Loader from "../Loader";
import MacroDetailPreview from "./MacroDetailPreview";
import styles from "./MacroPreview.module.css";
import MacroSectionPreview from "./MacroSectionPreview";

const MacroPreview = () => {
    const { previewId } = useParams()
    const [macro, setMacro] = useState(null)
    const [showDetails, setShowDetails] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])

    const messageHandler = (event) => {
        if (event.origin === `${window.location.protocol}//${window.location.host}`) {
            if (event.data?.type === `macroPreviewUpdateEvent-${previewId}`) {
                const json = JSON.parse(event.data.data)
                setMacro(json)
                setLoading(false)
            }
        }
    }

    useEffect(() => {

        window.addEventListener("message", messageHandler, false);

        window.scrollTo({
            top: 0,
        })

        return () => {
            window.removeEventListener("message", messageHandler)
        }
    }, [])

    return (
        <HeaderFooterLayout>
            <div className={styles.container}>
                {macro && !showDetails &&
                    <>
                        <div className={styles.section} style={{ paddingTop: '1rem', borderBottom: '1px solid rgba(var(--text-color-rgb), 12%)' }}>
                            <div className={styles.sectionInner}>
                                <div className={styles.main}>
                                    <div className={styles.macroLeft}>
                                        <div className={typo.subtitle}>Una carriera in {macro.name}</div>
                                        <div className={styles.description}>{macro.description}</div>
                                        <Each of={macro.careers} render={(career) => {
                                            return (
                                                <button className={styles.careerButton}>{career.name}</button>
                                            )
                                        }} />
                                    </div>
                                    <div className={styles.macroRight}>
                                        <div className={styles.playerWrapper}>
                                            {
                                                macro.video_url &&
                                                <ReactPlayer
                                                    url={macro.video_url}
                                                    controls
                                                    width="100%"
                                                    height="100%"
                                                    className={styles.player}
                                                    light={macro.thumbnail ?? ""}
                                                />
                                            }
                                            {
                                                !macro.video_url && macro.thumbnail &&
                                                <img src={macro.thumbnail} alt="" />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        {macro.content[0] &&
                            <div className={styles.section} style={{ borderBottom: '1px solid rgba(var(--text-color-rgb), 12%)' }}>
                                <div className={styles.sectionInner}>
                                    <MacroSectionPreview section={macro.content[0]} showPositions={false} />
                                </div>
                                <div className={styles.viewDetail}>
                                    <Button
                                        onClick={() => {
                                            setShowDetails(true)
                                        }}
                                    >
                                        VEDI DETTAGLI
                                        <ArrowIcon style={{ transform: 'rotate(180deg)' }}></ArrowIcon>
                                    </Button>
                                </div>
                            </div>
                        }
                    </>
                }
                {loading &&
                    <div className={styles.loader}>
                        <Loader />
                    </div>

                }
                {
                    macro && showDetails &&
                    <MacroDetailPreview macro={macro} onBack={() => setShowDetails(false)} />
                }
            </div>
        </HeaderFooterLayout>
    )
}

export default MacroPreview
