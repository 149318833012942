import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import { ReactComponent as CloseIcon } from "../assets/images/icons/ic-close.svg";
import { CourseType, Role, TagType } from "../common/constants";
import Back from "../components/Back";
import Button from "../components/Button";
import CheckbokSelect from "../components/CheckboxSelect";
import CourseInfoEditor from "../components/CourseInfoEditor";
import CourseProgramEditor from "../components/CourseProgramEditor";
import Select from "../components/Select";
import Spinner from "../components/Spinner";
import TagSelector from "../components/TagSelector";
import TextInput from "../components/TextInput";
import AlertDialog from "../components/dialogs/AlertDialog";
import IconsDialog from "../components/dialogs/IconsDialog";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import CoursePreview from "../components/previews/CoursePreview";
import { DialogStatus } from "../enums";
import typo from "../typography.module.css";
import styles from "./CourseEditor.module.css";
import CourseFaqEditor from "../components/CourseFaqEditor";

const placeholderVideoUrl = "https://www.youtube.com/watch?v=NpEaa2P7qZI"

const CourseEditor = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const { courseId } = useParams();
    const [course, setCourse] = useState(null)
    const [coursePartners, setCoursePartners] = useState(null)
    const [courseTeachers, setCourseTeachers] = useState(null)
    const [courseEditions, setCourseEditions] = useState(null)

    const [video, setVideo] = useState(null)
    const [thumbnail, setThumbnail] = useState(null)

    const [tags, setTags] = useState(null)
    const [teachers, setTeachers] = useState(null)
    const [partners, setPartners] = useState(null)

    const [saveDisabled, setSaveDisabled] = useState(true)

    const [status, setStatus] = useState(
        [
            { id: 0, name: 'Bozza', value: 'draft' },
            { id: 1, name: 'Pubblico', value: 'public' },
            { id: 2, name: 'Nascosto', value: 'hidden' }
        ]
    )


    const [type, setType] = useState(
        [
            { id: 0, name: 'Masterclass', value: CourseType.Masterclass },
            { id: 1, name: 'Professionalizzante', value: CourseType.Professional },
        ]
    )

    const [loading, setLoading] = useState(false)

    const [alert, setAlert] = useState({ open: false, title: '', text: '', actions: [], status: DialogStatus.Default })

    const [infoToEdit, setInfoToEdit] = useState(null)

    useEffect(() => {

        const getCourse = async () => {
            try {
                const course = await api.get(`/admin/courses/${courseId}`)
                setCourse(course)
                initializeStatus(course.status)
            }
            catch (e) {
                console.error(e)
            }
        }

        const getCourseTeachers = async () => {
            try {
                const courseTeachers = await api.get(`/admin/courses/${courseId}/teachers`)
                setCourseTeachers(courseTeachers)
            }
            catch (e) {
                console.error(e)
            }
        }

        const getCoursePartners = async () => {
            try {
                const coursePartners = await api.get(`/admin/courses/${courseId}/partners`)
                setCoursePartners(coursePartners)
            }
            catch (e) {
                console.error(e)
            }
        }

        const getCourseEditions = async () => {
            try {
                const courseEditions = await api.get(`/admin/courses/${courseId}/editions`)
                const filteredEditions = courseEditions.filter(e => {
                    return (
                        e.status === 'public' &&
                        new Date(e.expired_at) > new Date()
                    )
                })
                setCourseEditions(courseEditions)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (!course && courseId !== 'create') {
            getCourse()
            getCourseTeachers()
            getCoursePartners()
            getCourseEditions()
        } else if (courseId === "create") {
            setCourse({
                name: "",
                description: "",
                status: "draft",
                type: CourseType.Masterclass,
                duration: 8,
                video_url: "",
                thumbnail: "",
                tags: [],
                faq: [
                    { "name": "Esempio domanda", "description": "Esempio risposta" }
                ],
                info: [
                    {
                        "icon": "<svg width=\"24\" height=\"24\" fill=\"none\" viewBox=\"0 0 24 24\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M3 5.75A.75.75 0 0 1 3.75 5c2.663 0 5.258-.943 7.8-2.85a.75.75 0 0 1 .9 0C14.992 4.057 17.587 5 20.25 5a.75.75 0 0 1 .75.75V11c0 .338-.014.67-.04.996a6.5 6.5 0 0 0-8.33 9.809l-.355.143a.75.75 0 0 1-.55 0C5.958 19.676 3 16 3 11V5.75ZM23 17.5a5.5 5.5 0 1 0-11 0 5.5 5.5 0 0 0 11 0Zm-2.146-2.354a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708l1.646 1.647 3.646-3.647a.5.5 0 0 1 .708 0Z\" fill=\"#212121\"/></svg>",
                        "color": "#1F94FF",
                        "index": 0,
                        "title": "Placeholder",
                        "chosen": false,
                        "iconName": "ic_fluent_shield_checkmark_24_filled.svg",
                        "selected": false,
                        "_selected": false,
                        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non ante faucibus, eleifend ligula tempor, luctus ligula. Praesent sem mauris, semper et aliquet id, fermentum eu lectus."
                    },
                ],
                program: [
                    {
                        "name": "Introduzione",
                        "chosen": false,
                        "selected": false,
                        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non ante faucibus, eleifend ligula tempor, luctus ligula. Praesent sem mauris, semper et aliquet id, fermentum eu lectus."
                    },
                ],
                product: {
                    name: "",
                    price: 199.99,
                    discounted_price: 159.99
                }
            })
            setCourseTeachers([])
            setCoursePartners([])
            setCourseEditions([])
        }
    }, [courseId])

    useEffect(() => {
        const getTeachers = async () => {
            try {
                const teachers = await api.get(`/admin/teachers?role=${Role.Teacher}`)
                setTeachers(teachers)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (!teachers) {
            getTeachers()
        }
    }, [teachers])

    useEffect(() => {
        const getPartners = async () => {
            try {
                const partners = await api.get("/admin/partners")
                setPartners(partners)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (!partners) {
            getPartners()
        }
    }, [partners])

    useEffect(() => {
        const getTags = async () => {
            try {
                let tags = await api.get("/admin/tags?type=all")
                tags = tags.filter(t => {
                    return !course.tags.map(t => t.id).includes(t.id)
                })
                setTags(tags)
            }
            catch (e) {
                console.error(e)
            }
        }
        if (!tags && course) {
            getTags()
        }
    }, [tags, course])

    const initializeStatus = (status) => {
        switch (status) {
            case 'draft':
                setStatus([
                    { id: 0, name: 'Bozza', value: 'draft' },
                    { id: 1, name: 'Pubblico', value: 'public' },
                ])
                break
            case 'public':
                setStatus([
                    { id: 1, name: 'Pubblico', value: 'public' },
                    { id: 2, name: 'Nascosto', value: 'hidden' }
                ])
                break
            case 'hidden':
                setStatus([
                    { id: 1, name: 'Pubblico', value: 'public' },
                    { id: 2, name: 'Nascosto', value: 'hidden' }
                ])
                break
            default: return
        }
    }

    useEffect(() => {
        if (course) {
            switch (course.status) {
                case 'draft':
                    setSaveDisabled(course.name.trim() === '' || course.slug.trim() === '')
                    break
                case 'public':
                case 'hidden':
                    setSaveDisabled(
                        course.name.trim() === '' ||
                        course.description.trim() === '' ||
                        course.slug.trim() === '' ||
                        course.video_url.trim() === '' ||
                        course.thumbnail.trim() === '' ||
                        course.duration === '' ||
                        course.program.length === 0 ||
                        course.info.length === 0
                    )
                    break
                default: return
            }
        }
    }, [course])

    const update = async () => {
        setLoading(true)
        try {
            let form = new FormData()
            form.append('name', course.name)
            form.append('description', course.description)
            form.append('slug', course.slug)
            form.append('status', course.status)
            if (video) {
                form.append('videoFile', video)
            }
            if (thumbnail) {
                form.append('thumbnailFile', thumbnail)
            }

            form.append('duration', course.duration)
            form.append('video_url', course.video_url)
            form.append('thumbnail', course.thumbnail)
            form.append('price', course.product.price)
            form.append('discounted_price', course.product.discounted_price)
            form.append('info', JSON.stringify(course.info))
            form.append('program', JSON.stringify(course.program))
            form.append('tags', JSON.stringify(course.tags.map(t => t.id)))
            form.append('partners', JSON.stringify(coursePartners.map(p => p.id)))
            form.append('teachers', JSON.stringify(courseTeachers.map(t => t.id)))
            form.append('faq', JSON.stringify(course.faq))

            const updated = await api.put(`/admin/courses/${course.id}`, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })

            setCourse(updated)
            initializeStatus(updated.status)

            setAlert({
                open: true,
                title: 'Operazione Completata',
                text: 'I dati sono stati aggiornati con successo',
                status: DialogStatus.Success,
                actions: [
                    {
                        label: 'CHIUDI',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                        }
                    }
                ]
            })
        }
        catch (e) {
            console.error(e)
            setAlert({
                open: true,
                title: 'Operazione Fallita',
                text: e.detail,
                status: DialogStatus.Error,
                actions: [
                    {
                        label: 'OK',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                        }
                    }
                ]
            })
        }
        setLoading(false)

    }

    const create = async () => {
        setLoading(true)
        try {
            let form = new FormData()
            form.append('name', course.name)
            form.append('description', course.description)
            form.append('slug', course.slug)
            form.append('status', course.status)
            form.append('type', course.type)
            if (video) {
                form.append('videoFile', video)
            }
            if (thumbnail) {
                form.append('thumbnailFile', thumbnail)
            }

            form.append('duration', course.duration)
            form.append('video_url', course.video_url)
            form.append('thumbnail', course.thumbnail)
            form.append('price', course.product.price)
            form.append('discounted_price', course.product.discounted_price)
            form.append('info', JSON.stringify(course.info))
            form.append('faq', JSON.stringify(course.faq))
            form.append('program', JSON.stringify(course.program))
            form.append('tags', JSON.stringify(course.tags.map(t => t.id)))
            form.append('partners', JSON.stringify(coursePartners.map(p => p.id)))
            form.append('teachers', JSON.stringify(courseTeachers.map(t => t.id)))

            const created = await api.post(`/admin/courses`, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })

            setCourse(created)
            initializeStatus(created.status)

            setAlert({
                open: true,
                title: 'Operazione Completata',
                text: 'I dati sono stati aggiornati con successo',
                status: DialogStatus.Success,
                actions: [
                    {
                        label: 'CHIUDI',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                navigate(`/courses/${created.id}`)
                                return { ...prev }
                            })
                        }
                    }
                ]
            })
        }
        catch (e) {
            console.error(e)
            setAlert({
                open: true,
                title: 'Operazione Fallita',
                text: e.detail,
                status: DialogStatus.Error,
                actions: [
                    {
                        label: 'OK',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                        }
                    }
                ]
            })
        }
        setLoading(false)
    }

    const save = async () => {
        if (course.id) {
            update()
        }
        else {
            create()
        }
    }

    return (
        <HeaderFooterLayout hideFooter>
            <div className={styles.container}>
                <div className={styles.preview}>
                    <div className={styles.back}>
                        <Back onClick={() => { navigate("/shop_courses") }} />
                    </div>
                    {course &&
                        <CoursePreview course={course} editions={courseEditions} teachers={courseTeachers} partners={coursePartners} />
                    }
                </div>
                <div className={styles.edit}>
                    <div className={typo.subtitle}>Modifica</div>
                    {course &&
                        <>
                            <div className={styles.editSection}>
                                <div className={typo.caption}>STATO</div>
                                <Select selected={status.find(s => s.value === course?.status)} options={status} onSelect={(value) => {
                                    setCourse((prev) => {
                                        prev.status = value.value
                                        return { ...prev }
                                    })
                                }} />
                            </div>
                            <div className={styles.editSection}>
                                <div className={typo.caption}>TIPOLOGIA</div>
                                <Select selected={status.find(s => s.value === course?.type)} options={type} onSelect={(value) => {
                                    setCourse((prev) => {
                                        prev.type = value.value
                                        return { ...prev }
                                    })
                                }} />
                            </div>
                            <div className={styles.editSection}>

                                <div className={typo.caption}>NOME</div>
                                <TextInput
                                    style={{ fontSize: '.75rem' }}
                                    type="text"
                                    value={course?.name}
                                    placeholder="Nome"
                                    onKeyUp={(value) => {
                                        setCourse((prev) => {
                                            prev.name = value
                                            prev.slug = value?.replace(/[^a-zA-Z0-9\s-]/g, '') // Rimuovi tutti i caratteri tranne lettere e numeri
                                                .replace(/\s+/g, '-') // Sostituisci spazi con trattini
                                                .toLowerCase()
                                                .trim();
                                            return { ...prev }
                                        })
                                    }} />
                            </div>

                            <div className={styles.editSection}>
                                <div className={typo.caption}>SLUG</div>
                                <TextInput
                                    style={{ fontSize: '.75rem' }}
                                    type="text" value={course?.slug}
                                    placeholder="Slug"
                                    onKeyUp={(value) => {
                                        const newValue = value?.replace(/[^a-zA-Z0-9\s-]/g, '') // Rimuovi tutti i caratteri tranne lettere e numeri
                                            .replace(/\s+/g, '-') // Sostituisci spazi con trattini
                                            .toLowerCase()
                                            .trim(); // Converti tutto in minuscolo
                                        setCourse((prev) => {
                                            prev.slug = newValue
                                            return { ...prev }
                                        })
                                    }} />
                            </div>

                            <div className={styles.editSection}>
                                <div className={typo.caption}>DESCRIZIONE</div>
                                <textarea className={styles.description} value={course?.description} onChange={(e) => {
                                    setCourse((prev) => {
                                        prev.description = e.target.value
                                        return { ...prev }
                                    })
                                }} />
                            </div>

                            <div className={styles.editSection}>
                                <div className={typo.caption}>DURATA(H)</div>
                                <TextInput
                                    style={{ fontSize: '.75rem' }}
                                    type="number" value={course?.duration ?? '0'}
                                    placeholder="Durata"
                                    onKeyUp={(value) => {
                                        setCourse((prev) => {
                                            prev.duration = value
                                            return { ...prev }
                                        })
                                    }} />
                            </div>


                            <div className={styles.editSection}>
                                <div className={typo.caption}>PREZZO(€)</div>
                                <TextInput
                                    style={{ fontSize: '.75rem' }}
                                    type="number"
                                    numberType="float"
                                    value={course?.product.price ?? '0'}
                                    placeholder="Prezzo"
                                    onKeyUp={(value) => {
                                        setCourse((prev) => {
                                            prev.product.price = value
                                            return { ...prev }
                                        })
                                    }} />
                            </div>

                            <div className={styles.editSection}>
                                <div className={typo.caption}>PREZZO SCONTATO(€)</div>
                                <TextInput
                                    style={{ fontSize: '.75rem' }}
                                    type="number"
                                    numberType="float"
                                    value={course?.product.discounted_price ?? '0'}
                                    placeholder="Prezzo scontato"
                                    onKeyUp={(value) => {
                                        setCourse((prev) => {
                                            prev.product.discounted_price = value
                                            return { ...prev }
                                        })
                                    }} />
                            </div>

                            <div className={styles.editSection}>
                                <div className={typo.caption}>THUMBNAIL</div>
                                <TextInput
                                    style={{ fontSize: '.75rem' }}
                                    disabled={course?.thumbnail?.startsWith('blob:')}
                                    type="text" value={course?.thumbnail}
                                    placeholder="Thumbnail URL"
                                    onKeyUp={(value) => {
                                        setCourse((prev) => {
                                            prev.thumbnail = value ? value : ''
                                            return { ...prev }
                                        })
                                    }} />

                                <Dropzone
                                    onDrop={(files) => {
                                        const thumbnailUrl = URL.createObjectURL(files[0])
                                        setThumbnail(files[0])
                                        setCourse((prev) => {
                                            prev.thumbnail = thumbnailUrl
                                            return { ...prev }
                                        })
                                    }}
                                    accept={
                                        {
                                            'image/jpeg': ['.jpg', '.jpeg'],
                                            "image/png": ['.png'],
                                            "image/svg+xml": ['.svg'],
                                            "image/webp": ['.webp']
                                        }
                                    }
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <section style={{ display: 'flex', width: '100%', padding: 0, margin: 0 }}>
                                            <div {...getRootProps()} style={{ display: 'flex', width: '100%' }}>
                                                <input {...getInputProps()} />
                                                <div className={styles.dropzone}>
                                                    {course?.thumbnail &&
                                                        <div className={styles.thumbnailWrapper}>
                                                            <img src={course?.thumbnail} alt="" className={styles.thumbnail} />
                                                            <div className={styles.removeThumbnail} onClick={(e) => {
                                                                e.stopPropagation()
                                                                setThumbnail(null)
                                                                setCourse((prev) => {
                                                                    prev.thumbnail = ''
                                                                    return { ...prev }
                                                                })
                                                            }}>
                                                                <CloseIcon style={{ height: '16px', width: '16px' }} />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className={styles.dropzoneLabel}>
                                                        Trascina o seleziona un'immagine.
                                                    </div>
                                                    <Button
                                                        style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                                        additionalClass={styles.selectFileButton}
                                                    >
                                                        SCEGLI IMMAGINE
                                                    </Button>
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </div>

                            <div className={styles.editSection}>
                                <div className={typo.caption}>VIDEO</div>
                                <TextInput
                                    style={{ fontSize: '.75rem' }}
                                    disabled={video}
                                    type="text"
                                    value={course?.video_url}
                                    placeholder="Video URL"
                                    onKeyUp={(value) => {
                                        setCourse((prev) => {
                                            prev.video_url = value ? value : ''
                                            return { ...prev }
                                        })
                                    }} />

                                <Dropzone
                                    onDrop={(files) => {
                                        const videoUrl = URL.createObjectURL(files[0])
                                        setVideo(files[0])
                                        setCourse((prev) => {
                                            prev.video_url = videoUrl
                                            return { ...prev }
                                        })
                                    }}
                                    accept={
                                        {
                                            'video/mp4': ['.mp4', '.MP4'],
                                            "video/mov": ['.mov'],
                                            "video/m4v": ['.m4v'],
                                            "video/mpeg": ['.mpeg'],
                                        }
                                    }
                                >

                                    {({ getRootProps, getInputProps }) => (
                                        <section style={{ display: 'flex', width: '100%', padding: 0, margin: 0 }}>
                                            <div {...getRootProps()} style={{ display: 'flex', width: '100%' }}>
                                                <input {...getInputProps()} />
                                                <div className={styles.dropzone}>
                                                    {video &&
                                                        <div className={styles.playerWrapper}>
                                                            <div className={styles.removeVideo} onClick={() => {
                                                                setVideo(null)
                                                                setCourse((prev) => {
                                                                    prev.video_url = ""
                                                                    return { ...prev }
                                                                })
                                                            }}>
                                                                <CloseIcon style={{ height: '16px', width: '16px' }} />
                                                            </div>
                                                            <ReactPlayer
                                                                url={course.video_url}
                                                                controls
                                                                width="100%"
                                                                height="100%"
                                                                className={styles.player}
                                                            />
                                                        </div>
                                                    }
                                                    <div className={styles.dropzoneLabel}>
                                                        Trascina o seleziona un video.
                                                    </div>
                                                    <Button
                                                        style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                                        additionalClass={styles.selectFileButton}
                                                    >
                                                        SCEGLI VIDEO
                                                    </Button>
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </div>


                            {tags &&
                                <>
                                    <div className={styles.editSection}>
                                        <div className={typo.caption}>TAG</div>
                                        <TagSelector
                                            selected={course.tags.filter(t => t.type === TagType.General)}
                                            options={tags.filter(t => t.type === TagType.General)}
                                            placeholder={"Cerca tag"}
                                            onAdd={(item) => {
                                                const newOptions = tags.filter(t => t.id !== item.id)
                                                setTags([...newOptions])

                                                setCourse((prev) => {
                                                    if (!prev.tags.map(t => t.id).includes(item.id)) {
                                                        prev.tags.push(item)
                                                    }
                                                    return { ...prev }
                                                })
                                            }}
                                            onRemove={(item) => {
                                                const newOptions = [...tags]
                                                if (!newOptions.map(t => t.id).includes(item.id)) {
                                                    newOptions.push(item)
                                                }
                                                setTags([...newOptions])

                                                setCourse((prev) => {
                                                    prev.tags = prev.tags.filter(t => t.id !== item.id)
                                                    return { ...prev }
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className={styles.editSection}>
                                        <div className={typo.caption}>FEATURE TAG</div>
                                        <TagSelector
                                            selected={course.tags.filter(t => t.type === TagType.Feature)}
                                            options={tags.filter(t => t.type === TagType.Feature)}
                                            placeholder={"Cerca tag"}
                                            onAdd={(item) => {
                                                const newOptions = tags.filter(t => t.id !== item.id)
                                                setTags([...newOptions])

                                                setCourse((prev) => {
                                                    if (!prev.tags.map(t => t.id).includes(item.id)) {
                                                        prev.tags.push(item)
                                                    }
                                                    return { ...prev }
                                                })
                                            }}
                                            onRemove={(item) => {
                                                const newOptions = [...tags]
                                                if (!newOptions.map(t => t.id).includes(item.id)) {
                                                    newOptions.push(item)
                                                }
                                                setTags([...newOptions])

                                                setCourse((prev) => {
                                                    prev.tags = prev.tags.filter(t => t.id !== item.id)
                                                    return { ...prev }
                                                })
                                            }}
                                        />
                                    </div>
                                </>
                            }
                            {partners && coursePartners && course.type === CourseType.Professional &&
                                <div className={styles.editSection}>
                                    <div className={typo.caption}>PARTNER</div>
                                    <CheckbokSelect
                                        placeholder="Cerca partner"
                                        options={
                                            partners.map(p => {
                                                p._selected = coursePartners.map(cp => cp.id).includes(p.id)
                                                return p
                                            })}
                                        appearance="image"
                                        onSelect={(items) => {
                                            setCoursePartners(items)
                                        }} />
                                </div>
                            }
                            {course &&
                                <div className={styles.editSection}>
                                    <div className={typo.caption}>PERCHÈ PARTECIPARE</div>
                                    <CourseInfoEditor info={course.info} onChange={(info) => {
                                        setCourse((prev) => {
                                            if (info !== prev.info) {
                                                prev.info = info
                                            }
                                            return { ...prev }
                                        })
                                    }}
                                        shouldChangeIcon={(item, index) => {
                                            item.index = index
                                            setInfoToEdit(item)
                                        }}
                                    />
                                </div>
                            }
                            {course && course.program &&
                                <div className={styles.editSection}>
                                    <div className={typo.caption}>PROGRAMMA</div>
                                    <CourseProgramEditor courseType={course.type} program={course.program} onChange={(program) => {
                                        setCourse((prev) => {
                                            if (program !== prev.program) {
                                                prev.program = program
                                                let duration = program.map(p => parseInt(p.duration)).reduce((acc, current) => acc + current, 0)
                                                if (!isNaN(duration)) {
                                                    prev.duration = duration
                                                }
                                            }
                                            return { ...prev }
                                        })
                                    }}
                                    />
                                </div>
                            }

                            {teachers && courseTeachers &&
                                <div className={styles.editSection}>
                                    <div className={typo.caption}>DOCENTI</div>
                                    <CheckbokSelect
                                        placeholder="Cerca docente"
                                        options={
                                            teachers.map(t => {
                                                t._name = `${t.name} ${t.surname}`
                                                t._selected = courseTeachers.map(ct => ct.id).includes(t.id)
                                                return t
                                            })}
                                        appearance="default"
                                        onSelect={(items) => {
                                            setCourseTeachers(items)
                                        }} />
                                </div>
                            }
                            {course &&
                                <div className={styles.editSection}>
                                    <div className={typo.caption}>FAQ</div>
                                    <CourseFaqEditor
                                        faq={course.faq ?? []}
                                        onChange={(faq) => {
                                            setCourse((prev) => {
                                                if (faq !== prev.faq) {
                                                    prev.faq = faq
                                                }
                                                return { ...prev }
                                            })
                                        }}
                                    />
                                </div>
                            }

                            <div className={styles.save}>
                                <Button disabled={saveDisabled || loading} fullWidth onClick={save}>
                                    <>
                                        {loading &&
                                            <Spinner />
                                        }
                                        {course?.status === 'draft' && "SALVA IN BOZZA"}
                                        {course?.status === 'public' && "PUBBLICA"}
                                        {course?.status === 'hidden' && "NASCONDI"}
                                    </>
                                </Button>
                            </div>
                        </>
                    }
                </div>
            </div>
            <AlertDialog
                open={alert.open}
                title={alert.title}
                text={alert.text}
                actions={alert.actions}
                status={alert.status}
                onClose={() => {
                    setAlert((prev) => {
                        prev.open = false
                        return { ...prev }
                    })
                }
                } />

            {infoToEdit &&
                <IconsDialog open={infoToEdit !== null}
                    selected={infoToEdit?.iconName}
                    onClose={() => {
                        setInfoToEdit(null)
                    }}
                    onSelect={async (icon) => {
                        const data = icon.data
                        let info = course.info.map((info, index) => {
                            if (index === infoToEdit.index) {
                                info.icon = data
                                info.iconName = icon.svgFileName
                            }
                            return info
                        })
                        setCourse((prev) => {
                            prev.info = info
                            return { ...prev }
                        })
                        setInfoToEdit(null)
                    }} />
            }
        </HeaderFooterLayout>
    )
}

export default CourseEditor
