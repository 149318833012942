import React, { useContext, useEffect } from "react"
import styles from "./Dashboard.module.css"
import typo from "../typography.module.css"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import { useTranslation } from "react-i18next"
import MainContext from "../common/MainContext"
import { Helmet, HelmetProvider } from "react-helmet-async"

const Dashboard = () => {

    const { t } = useTranslation()
    const context = useContext(MainContext)

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])

    const a = 0
    const b = true
    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Dashboard</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={typo.title}>
                            {t('dashboard.title', { name: context.user?.name ?? '' })}
                        </div>
                        <div className={styles.divider} />
                        <div>
                            <div className={typo.subtitle}>
                                Release Notes v0.0.1
                            </div>
                            <div className={typo.headline}>
                                13 Aprile 2024
                            </div>
                        </div>
                        <ul>
                            <li>
                                <strong>Struttura progetto</strong><br></br>
                                • Link side menu adattati per la versione docenti<br />
                                • Settate rotte per le pagine principali (attualmente senza contenuto)
                            </li>
                        </ul>
                        <div className={styles.divider} />
                    </div>
                </div>
            </div>
        </HeaderFooterLayout>
    )
}

export default Dashboard
