import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import api from "../api";
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg";
import { ReactComponent as EmptyIllustration } from "../assets/images/illustrations/il-empty.svg";
import { Each } from "../common/Each";
import Button from "../components/Button";
import ReviewCard from "../components/cards/ReviewCard";
import EmptyContent from "../components/EmptyContent";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import Pagination from "../components/Pagination";
import TextInput from "../components/TextInput";
import typo from "../typography.module.css";
import styles from "./Reviews.module.css";
import { useNavigate } from "react-router-dom";
import AlertDialog from "../components/dialogs/AlertDialog";

const Reviews = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()

  const [showPagination, setShowPagination] = useState(true)
  const [page, setPage] = useState(0)
  const [per, setPer] = useState(10)
  const [reviews, setReviews] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loadingPage, setLoadingPage] = useState(true)

  useEffect(() => {

  }, [])

  useEffect(() => {
    getReviews()
  }, [page])

  const getReviews = async (q = null) => {
    setLoading(true)
    try {
      const data = q ?
        await api.get(`/admin/reviews?q=${q}`) :
        await api.get(`/admin/reviews?page=${page}&per=${per}`)
      const { reviews, count } = data
      setReviews(reviews)
      setCount(count)
      setShowPagination(count > per)
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
    if (loadingPage) {
      setLoadingPage(false)
    }
  }

  const onSearch = async (searchText) => {
    if (loading)
      return

    if (searchText.length > 0) {
      await getReviews(searchText.toLowerCase())
      setShowPagination(false)
    } else {
      await getReviews()
    }
  }

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>
            Recensioni
          </title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <div className={styles.header}>
              <div className={typo.title} style={{ display: 'flex', justifyContent: 'space-between' }}>Recensioni</div>
              <Button style={{ padding: ".75rem 1rem" }} onClick={() => navigate(`/reviews/create`)}>
                AGGIUNGI
                <AddIcon />
              </Button>
            </div>

            <TextInput
              onKeyUp={onSearch}
              type="search"
              placeholder={t("search")} />

            <div className={styles.reviews}>
              {
                loading &&
                <Each
                  of={Array(per).fill("")}
                  render={() => (
                    <ReviewCard loading />
                  )}
                />
              }
              {
                !loading &&
                <Each
                  of={reviews}
                  render={(review) => (
                    <div className={styles.review}>
                      <ReviewCard
                        review={review}
                        onEdit={() => navigate(`/reviews/${review.id}`)}
                        onDelete={getReviews}
                      />
                    </div>
                  )}
                />
              }
              {
                !loading && reviews.length === 0 &&
                <EmptyContent
                  Illustration={EmptyIllustration}
                  title={"Nessuna recensione trovata"}
                />
              }
            </div>
            {
              showPagination &&
              <Pagination total={count} per={per} page={page} onChange={p => setPage(p)} loading={loadingPage} />
            }
          </div>
        </div>
      </div>
    </HeaderFooterLayout>

  );
};

export default Reviews;
