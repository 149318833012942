import { useEffect, useState } from "react"
import styles from "./CheckboxSelect.module.css"
import { Each } from "../common/Each"
import Checkbox from "./Checkbox"
import typo from "../typography.module.css"

const CheckbokSelect = ({ options, onSelect, appearance = "default", imageField = "logo", placeholder = "Cerca", style = {} }) => {

    const [opt, setOpt] = useState([])
    const [filterOptions, setFilterOptions] = useState([])

    useEffect(() => {
        if (options.length > 0) {
            setOpt(options)
            setFilterOptions(options)
        }
    }, [options])

    useEffect(() => {
        if (opt) {
            const sorted = opt.sort((a, b) => {
                if (a._selected && !b._selected) {
                    return -1
                }
                if (!a._selected && b._selected) {
                    return 1
                }
                return 0
            })
            setFilterOptions(sorted)
        }
    }, [opt])

    const handleOnChange = (e) => {
        const value = e.target.value.toLowerCase().trim().replaceAll(' ', '')
        const newOpt = opt.filter(o => {
            return o.name.toLowerCase().trim().replaceAll(' ', '').includes(value)
        })
        setFilterOptions(newOpt)
    }

    return (
        <div className={styles.container} style={{ ...style }}>
            <input type="text" placeholder={placeholder} className={styles.search} onChange={handleOnChange} />
            <div className={styles.items}>
                <Each of={filterOptions} render={(item) => {
                    return (
                        <div className={styles.item}>
                            <Checkbox value={item._selected} key={item.id} onChange={(checked) => {
                                const newOpt = opt.map(o => {
                                    if (o.id === item.id) {
                                        o._selected = checked
                                    }
                                    return o
                                })
                                onSelect(newOpt.filter(o => o._selected))
                                setOpt(newOpt)
                            }}
                                style={{ transform: 'translateY(-3px)' }}
                            />
                            {appearance === "default" &&
                                <div className={styles.itemName}>{item._name}</div>
                            }
                            {appearance === "image" &&
                                <img src={item[imageField]} alt="" className={styles.logo} />
                            }
                        </div>
                    )
                }} />
                {filterOptions.length === 0 &&
                    <div className={typo.headline} style={{ paddingTop: '1rem' }}>Nessun risultato</div>
                }
            </div>
        </div>
    )

}

export default CheckbokSelect
