import { Helmet, HelmetProvider } from "react-helmet-async"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import TagsSettings from "../components/settings/TagsSettings"
import PartnersSettings from "../components/settings/PartnersSettings"
import typo from "../typography.module.css"
import styles from "./Settings.module.css"

const Settings = () => {


    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Impostazioni</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={typo.title} style={{ display: 'flex', justifyContent: 'space-between' }}>Impostazioni</div>
                        <TagsSettings />
                        <PartnersSettings />
                    </div>
                </div>
            </div>
        </HeaderFooterLayout >
    )
}

export default Settings
