import styles from "./StatusBadge.module.css"

const StatusBadge = ({ label = "", color }) => {


  return (
    <div className={styles.statusContainer}>
      <div className={styles.statusBadge} style={{ "--color": color }}>{label.toUpperCase()}</div>
    </div>
  )
}

export default StatusBadge
