import { useEffect } from "react";
import ReactPlayer from "react-player";
import { palette } from "../../common/constants";
import Button from "../Button";
import RatingBadge from "../RatingBadge";
import styles from "./ReviewPreview.module.css";
import useWindowDimensions from "../../common/hooks/useWindowDimensions"
import { ReactComponent as PlayIcon } from "../../assets/images/illustrations/il-play-button.svg"
import Markdown from "react-markdown"

const ReviewPreview = ({ review }) => {

  const { width } = useWindowDimensions()

  function getInitials(input) {
    // Dividi la stringa in parole usando lo spazio come delimitatore
    const words = input.split(' ');

    // Prendi la prima lettera di ciascuna parola e uniscile
    const initials = words.map(word => word.charAt(0)).join('');

    // Restituisci la stringa di iniziali
    return initials;
  }

  function getRandomColor() {
    // Calcola un indice casuale compreso tra 0 e la lunghezza dell'array meno 1
    const randomIndex = Math.floor(Math.random() * palette.length);

    // Restituisci l'elemento all'indice casuale
    return palette[randomIndex];
  }

  useEffect(() => {
    if (review) {
      review.color = getRandomColor()
    }
  }, [review])

  return (
    <div className={styles.container}>
      {review.media && review.media_type === 'video' &&
        <div className={styles.playerContainer}>
          <ReactPlayer
            url={review.media}
            controls
            width="100%"
            height="100%"
            className={styles.player}
            playIcon={<PlayIcon />}
            light={true}
            config={
              {
                youtube: {
                  playerVars: { showinfo: 0, modestbranding: 1, fs: 0, autoplay: 1 }
                }
              }
            }
          />
        </div>
      }
      {review.media && review.media_type === 'image' &&
        <div className={styles.imageContainer}>
          <img src={review.media} alt="" className={styles.image} />
        </div>
      }
      {review.message &&
        <div className={styles.message}>
          <Markdown>
            {review.message}
          </Markdown>
        </div>
      }
      <div className={styles.user}>
        <div className={styles.userInfo}>
          {(review.user_avatar || review.user?.picture) &&
            <img src={review.user_avatar || review.user?.picture} alt="" className={styles.avatar} />
          }
          {!(review.user_avatar || review.user?.picture) &&
            <div style={{ backgroundColor: review.color ?? 'var(--primary)' }} className={styles.initials}>{getInitials(review.user_name ?? "")}</div>
          }
          <div className={styles.info}>
            <div className={styles.username}>
              {review.user_name || review.user?.name}
            </div>
            {width <= 540 &&
              <RatingBadge rating={review.rating} />
            }
            {review.url && width > 540 &&
              <Button appearance="text" style={{ padding: 0, fontSize: '.75rem', whiteSpace: 'nowrap' }} onClick={() => { window.location.href = review.url }}>{review.platform ? `VEDI SU ${review.platform.toUpperCase()}` : "VEDI"}</Button>
            }
          </div>
        </div>
        {width > 540 &&
          <RatingBadge rating={review.rating} />
        }
      </div>
    </div>
  )
}

export default ReviewPreview
