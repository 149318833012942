import { useCallback, useEffect, useMemo, useState } from "react"
import styles from "./Checkbox.module.css"
import { ReactComponent as CheckIcon } from "../assets/images/icons/ic-check.svg"

const Checkbox = ({ label = "", value = false, onChange = null, disabled = false, size = "2rem" }) => {

  const [_value, setValue] = useState(value)
  const [isCheckHover, setIsCheckHover] = useState(false)

  const checkStyle = useMemo(() => {

    const defaultStyle = {
      cursor: onChange ? "pointer" : "default",
      color: "var(--sf-light-green)",
      backgroundColor: "#FFFFFF",
      width: size,
      height: size,
    }
    const filled = {
      cursor: onChange ? "pointer" : "default",
      color: "#FFFFFF",
      backgroundColor: "var(--sf-light-green)",
      width: size,
      height: size,
    }
    const hovered = {
      cursor: onChange ? "pointer" : "default",
      color: "var(--sf-light-green)",
      backgroundColor: "rgba(var(--sf-light-green-rgb), 0.2)",
      width: size,
      height: size,
    }
    if (disabled)
      return defaultStyle
    if (_value) {
      return filled
    }
    return isCheckHover ? hovered : defaultStyle

  }, [isCheckHover, _value])

  useEffect(() => setValue(value), [value])

  const handleChange = useCallback((e) => {
    if (onChange === null) return
    if (disabled) return

    setValue(!_value)

    if (onChange) {
      onChange(!_value)
    }
  }, [_value, onChange])


  return (
    <div className={disabled ? `${styles.container} ${styles.disabled}` : styles.container}>
      {
        label &&
        <div className={styles.label}>{label}</div>
      }
      {
        disabled &&
        <div className={styles.check} style={{ width: size, height: size }} />
      }
      {
        _value === false && isCheckHover === false && !disabled &&
        <div className={styles.check} style={checkStyle}
          onMouseEnter={() => setIsCheckHover(true)}
          onMouseLeave={() => setIsCheckHover(false)}
          onClick={handleChange}
        />
      }
      {
        _value === false && isCheckHover === true && !disabled &&
        < CheckIcon className={styles.check} style={checkStyle}
          onMouseEnter={() => setIsCheckHover(true)}
          onMouseLeave={() => setIsCheckHover(false)}
          onClick={handleChange} />
      }
      {
        _value === true &&
        <CheckIcon className={styles.check} style={checkStyle}
          onMouseEnter={() => setIsCheckHover(true)}
          onMouseLeave={() => setIsCheckHover(false)}
          onClick={handleChange} />
      }
    </div>
  )
}

export default Checkbox
