import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Dropzone from "react-dropzone"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import api from "../api"
import comuni from "../assets/comuni.json"
import { ReactComponent as BookIcon } from "../assets/images/icons/ic-book-active.svg"
import { ReactComponent as CalendarIcon } from "../assets/images/icons/ic-calendar.svg"
import { ReactComponent as CityIcon } from "../assets/images/icons/ic-city.svg"
import { ReactComponent as ClockIcon } from "../assets/images/icons/ic-clock.svg"
import { ReactComponent as DeleteIcon } from "../assets/images/icons/ic-delete.svg"
import { ReactComponent as PencilIcon } from "../assets/images/icons/ic-edit.svg"
import { ReactComponent as EditIcon } from "../assets/images/icons/ic-image-edit.svg"
import { ReactComponent as LessonIcon } from "../assets/images/icons/ic-lessons-active.svg"
import { ReactComponent as MoneybagIcon } from "../assets/images/icons/ic-moneybag.svg"
import { ReactComponent as StopIcon } from "../assets/images/icons/ic-stop.svg"
import { ReactComponent as StudentsIcon } from "../assets/images/icons/ic-students-active.svg"
import { ReactComponent as RepeatIcon } from "../assets/images/icons/ic-repeat.svg"
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg"
import { ReactComponent as UnlockIcon } from "../assets/images/icons/ic-unlock.svg"
import { ReactComponent as InsertImageIllustration } from "../assets/images/illustrations/il-insert-image.svg"
import UserPlaceholder from "../assets/images/placeholders/user-placeholder.png"
import universities from "../assets/universities.json"
import { AccountStatus, ButtonStatus } from "../common/constants"
import { Each } from "../common/Each"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import Back from "../components/Back"
import Button from "../components/Button"
import Card from "../components/cards/Card"
import CourseBadge from "../components/CourseBadge"
import AlertDialog from "../components/dialogs/AlertDialog"
import Dialog from "../components/dialogs/Dialog"
import DropdownSelection from "../components/DropdownSelection"
import ImageCropper from "../components/ImageCropper"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import Loader from "../components/Loader"
import MeatBallsMenu from "../components/MeatBallsMenu"
import RadarChart from "../components/RadarChart"
import Skeleton from "../components/Skeleton"
import TextInput from "../components/TextInput"
import { DialogStatus } from "../enums"
import typo from "../typography.module.css"
import { capitalize, formatDateV2 } from "../utils"
import styles from "./Student.module.css"
import Checkbox from "../components/Checkbox"

const Student = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { width } = useWindowDimensions()
    const { studentId } = useParams()
    const dateOptions = { day: 'numeric', month: 'long', year: 'numeric' }

    const [student, setStudent] = useState(null)
    const [studentLoading, setStudentLoading] = useState(false)
    const [profilePic, setProfilePic] = useState(null)
    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [mandatorySurvey, setMandatorySurvey] = useState(false)
    const [age, setAge] = useState('')
    const [ageNumeric, setAgeNumeric] = useState(0)
    const [city, setCity] = useState('')
    const [employment, setEmployment] = useState('')
    const [gender, setGender] = useState('')
    const [university, setUniversity] = useState('')
    const [error, setError] = useState(null)
    const [loadingCourses, setLoadingCourses] = useState(false)
    const [courses, setCourses] = useState([])
    const [availableCourses, setAvailableCourses] = useState([])
    const [editions, setEditions] = useState([])
    const [selectedCourse, setSelectedCourse] = useState(null)
    const [selectedEdition, setSelectedEdition] = useState(null)
    const [addCourseMode, setAddCourseMode] = useState(false)
    const [editEditionMode, setEditEditionMode] = useState(false)
    const [loadingEditions, setLoadingEditions] = useState(false)
    const [dialogTimeout, setDialogTimeout] = useState(null)

    const [hasChanges, setHasChanges] = useState(false)
    const [savingStatus, setSavingStatus] = useState(null)

    const [dialogOpen, setDialogOpen] = useState(false)
    const [imgFile, setImgFile] = useState(null)

    const [cropMode, setCropMode] = useState(false)
    const [alert, setAlert] = useState({
        open: false,
        title: "",
        message: "",
        status: DialogStatus.Default,
        actions: []
    })

    const employmentOptions = useMemo(() => [
        {
            id: 0,
            label: t(`profile.employments.student`),
            value: 'student'
        },
        {
            id: 1,
            label: t(`profile.employments.worker`),
            value: 'worker'
        },
        {
            id: 2,
            label: t(`profile.employments.studentWorker`),
            value: 'studentWorker'
        },
        {
            id: 3,
            label: t(`profile.employments.neither`),
            value: 'neither'
        }
    ], [])

    const genders = useMemo(() => [
        {
            id: 0,
            label: "Maschio",
            value: "male"
        },
        {
            id: 1,
            label: "Femmina",
            value: "female"
        },
        {
            id: 2,
            label: "Altro",
            value: "other"
        }
    ], [])

    const radarDatasets = useMemo(() => [
        {
            values: [34, 57, 81, 45, 90],
            labels: ['Estroversione', 'Apertura', 'Gradevolezza', 'Coscienziosità', 'Stabilità'],
            colors: ['#FAB666', '#60D265', '#4CB4FF', '#FA6676', '#B966FA'],
            tension: 1,
            stroke: 'rgb(47, 198, 160)',
            strokeWidth: 2,
            fill: 'rgba(47, 198, 160, 10%)'
        }
    ], [])

    const radarOptions = useMemo(() => {
        return {
            grid: {
                paddingVertical: 40,
                paddingHorizontal: 100,
                levels: 4,
                sideLength: 100,
                borderRadius: 10,
                fill: '#2A2A2A04',
                stroke: '#2A2A2A10',
                strokeWidth: 2
            },
            labels: {
                dots: true,
                dotColors: ['#FAB666', '#60D265', '#4CB4FF', '#FA6676', '#B966FA'],
                dotsRadius: 5,
                dotsDelta: 1.25,
                font: {
                    family: 'Manrope',
                    size: '14px',
                    weight: '800'
                },
                padding: 12
            }
        }
    }, [])

    const dropzoneRef = useRef(null)
    const ref = useRef(null)

    useEffect(() => {
        const getStudent = async () => {
            setStudentLoading(true)
            try {
                const student = await api.get(`/admin/users/${studentId}`)
                setStudent(student)
                setStudentLoading(false)
                return student
            } catch (e) {
                setStudentLoading(false)
                if (e.code === 3) {
                    setError({ code: -1, message: "Studente inesistente" })
                }
                console.error(e)
            }
        }

        getStudent()
        getAvailableCourses().then(() => {
            getCourses()
        })

        return () => {
            if (dialogTimeout) {
                clearTimeout(dialogTimeout)
            }
        }
    }, [])

    useEffect(() => {
        if (courses.length === 0)
            return

        const acs = []
        for (const ac of availableCourses) {
            if (!courses.find(c => c.id === ac.id) && ac.status === "public") {
                acs.push(ac)
            }
        }
        setAvailableCourses(acs)
    }, [courses])

    useEffect(() => {
        if (student) {
            setName(student.name)
            setSurname(student.surname)
            setEmployment(student.employment)
            setGender(student.gender)
            setUniversity(student.university)
            setCity(student.city)
            setAgeNumeric(student.age)
            setMandatorySurvey(student.mandatory_survey ?? false)
        }
    }, [student])

    useEffect(() => {
        if (student) {
            setHasChanges(
                (name !== student.name) ||
                (surname !== student.surname) ||
                (employment !== student.employment) ||
                (gender !== student.gender) ||
                (university !== student.university) ||
                (ageNumeric !== student.age) ||
                (city !== student.city) ||
                ((profilePic && profilePic !== student.profilePic) ?? false) ||
                (mandatorySurvey !== student.mandatory_survey)
            )
        }
    }, [student, name, surname, employment, gender, university, ageNumeric, city, profilePic, mandatorySurvey])

    useEffect(() => {
        if (selectedCourse) {
            getEditions()
        }
    }, [selectedCourse])

    const saveChanges = async () => {
        setSavingStatus(ButtonStatus.Loading)
        try {
            const formData = new FormData()
            formData.append("name", name);
            formData.append("surname", surname);
            if (city) formData.append("city", city);
            if (ageNumeric > 0) formData.append("age", ageNumeric)
            if (gender) formData.append("gender", gender)
            if (university) formData.append("university", university); // TODO verificare con il Team SF se tenerla
            if (employment) formData.append("employment", employment)
            formData.append("mandatory_survey", mandatorySurvey)

            if (profilePic) {
                let blob = await fetch(profilePic).then(r => r.blob());
                formData.append("picture", blob, `${student.id}-picture.jpg`);
            }

            try {
                const student = await api.put(`/admin/users/${studentId}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                setStudent(student)
            } catch (e) {
                throw new Error("Non è stato possibile salvare le modifiche.")
            }

            // setDialogOpen(true)
            setAlert({ open: true, title: t('profile.saved'), message: t('profile.changesSaved'), status: DialogStatus.Success })
            setSavingStatus(ButtonStatus.Success)
        } catch (e) {
            console.error(e)
            setSavingStatus(ButtonStatus.Error)
        }
    }

    const resetAlert = useCallback(() => {
        setAlert({
            open: false,
            title: "",
            message: "",
            status: DialogStatus.Default,
            actions: []
        })
    }, [])

    const changeAccountStatus = useCallback(async (status) => {
        setAlert(a => { return { ...a, status: DialogStatus.Loading } })

        try {
            const student = await api.patch(`/admin/users/${studentId}`, {
                status
            })
            setStudent(student)
            setAlert(a => { return { ...a, status: DialogStatus.Success } })
        } catch (e) {
            console.error(e)
            setAlert(a => { return { ...a, status: DialogStatus.Error } })
        }
    }, [studentId])

    const onAgeChange = (value) => {
        setAge(value)
        const numericAge = parseInt(value)
        if (numericAge < 13) {
            setError({ code: -1, message: t('errors.invalidAge') })
        }
        else {
            setError(null)
        }
        if (!isNaN(numericAge)) {
            setAgeNumeric(numericAge)
        }
    }

    const getEditions = async () => {
        setLoadingEditions(true)
        try {
            const editions = await api.get(`/admin/courses/${selectedCourse.id}/editions`)
            setEditions(editions
                .filter(e => e.status === "public")
                .sort((a, b) => a.created_at < b.created_at)
            )
        } catch (e) {
            console.error(e)
        }
        setLoadingEditions(false)
    }

    const getEditionStart = (edition) => {
        let lessons = edition.modules.flatMap(module => module.lessons)
        const objectWithMinStartAt = lessons.reduce((min, current) => {
            return new Date(current.starts_at) < new Date(min.starts_at) ? current : min;
        }, lessons[0]);
        return objectWithMinStartAt?.starts_at
    }

    const getEditionEnd = (edition) => {
        let lessons = edition.modules.flatMap(module => module.lessons)
        const objectWithMaxStartAt = lessons.reduce((max, current) => {
            return new Date(current.starts_at) > new Date(max.starts_at) ? current : max;
        }, lessons[0]);
        return objectWithMaxStartAt?.starts_at
    }

    const flatLesson = (edition) => {
        let lessons = edition.modules.flatMap(module => module.lessons)
        return lessons
    }

    const getAvailableCourses = async () => {
        try {
            const courses = await api.get(`/admin/courses`)
            setAvailableCourses(courses)
        } catch (e) {
            console.error(e)
        }
    }

    const getCourses = async () => {
        setLoadingCourses(true)
        try {
            const courses = await api.get(`/admin/users/${studentId}/courses`)
            setCourses(courses)
        } catch (e) {
            console.error(e)
        }
        setLoadingCourses(false)
    }

    const removeCourse = async (courseId, editionId) => {
        setAlert(a => {
            return {
                ...a,
                status: DialogStatus.Loading,
                actions: a.actions.map(action => ({ ...action, disabled: true }))
            }
        })
        try {
            editionId ?
                await api.delete(`/admin/users/${studentId}/courses/${courseId}/editions/${editionId}`) :
                await api.delete(`/admin/users/${studentId}/courses/${courseId}`)

            setSelectedCourse(null)
            setSelectedEdition(null)
            setEditEditionMode(false)
            setAddCourseMode(false)
            getAvailableCourses().then(r => {
                getCourses()
            })
            setAlert(a => {
                return {
                    ...a,
                    status: DialogStatus.Success,
                }
            })
            setDialogTimeout(setTimeout(resetAlert, 2000))
        } catch (e) {
            console.error(e)
            setAlert(a => {
                return {
                    ...a,
                    status: DialogStatus.Error,
                    actions: a.actions.map(action => ({ ...action, disabled: false }))
                }
            })
        }
    }

    const subscribe = async (courseId, editionId) => {
        setAlert(a => {
            return {
                ...a,
                status: DialogStatus.Loading,
                actions: a.actions.map(action => ({ ...action, disabled: true }))
            }
        })
        try {
            editionId ?
                await api.post(`/admin/users/${studentId}/courses/${courseId}/editions/${editionId}`) :
                await api.post(`/admin/users/${studentId}/courses/${courseId}`)

            setSelectedCourse(null)
            setSelectedEdition(null)
            setEditEditionMode(false)
            setAddCourseMode(false)
            getAvailableCourses().then(r => {
                getCourses()
            })
            setAlert(a => {
                return {
                    ...a,
                    status: DialogStatus.Success,
                }
            })
            setDialogTimeout(setTimeout(resetAlert, 2000))
        } catch (e) {
            console.error(e)
            setAlert(a => {
                return {
                    ...a,
                    status: DialogStatus.Error,
                    actions: a.actions.map(action => ({ ...action, disabled: false }))
                }
            })
        }
    }

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Studente</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <Back onClick={() => navigate("/users/students")} />
                        <div className={styles.content}>
                            <div className={styles.leftColumn}>
                                <div className={styles.student}>
                                    <img className={styles.profilePic} src={(profilePic || student?.picture) ?? UserPlaceholder} alt='' />
                                    {
                                        studentLoading &&
                                        <Skeleton type="rect" width="164px" height="24px" borderRadius={"12px"} />
                                    }
                                    {
                                        !studentLoading &&
                                        <div className={styles.bio}>
                                            <div className={styles.username}>{`${student?.name ?? ""} ${student?.surname ?? ""}`}</div>
                                            <div className={styles.email}>{student?.email}</div>
                                        </div>

                                    }
                                    <Button
                                        accentColor={"var(--tertiary)"}
                                        onClick={() => {
                                            setDialogOpen(true)
                                        }}
                                    >
                                        <EditIcon />
                                        {t("profile.edit").toUpperCase()}
                                    </Button>
                                    {
                                        student?.status === AccountStatus.Active &&
                                        <Button
                                            accentColor={"var(--secondary)"}
                                            onClick={() => {
                                                setAlert({
                                                    open: true,
                                                    title: "Sospensione studente",
                                                    message: "Lo studente non potrà più accedere alla piattaforma studente. Sei sicuro di voler procedere?",
                                                    status: DialogStatus.Default,
                                                    actions: [
                                                        {
                                                            label: "ANNULLA",
                                                            onClick: resetAlert,
                                                            inverse: true
                                                        },
                                                        {
                                                            label: "SI, SOSPENDI",
                                                            onClick: () => changeAccountStatus(AccountStatus.Suspended),
                                                        }
                                                    ]
                                                })
                                            }}
                                        >
                                            <StopIcon style={{ transform: "rotate(-45deg)" }} />
                                            SOSPENDI
                                        </Button>
                                    }

                                    {
                                        student?.status === AccountStatus.Suspended &&
                                        <Button
                                            accentColor={"var(--primary)"}
                                            onClick={() => {
                                                setAlert({
                                                    open: true,
                                                    title: "Attivazione studente",
                                                    message: "Lo studente potrà accedere alla piattaforma studenti. Sei sicuro di voler procedere?",
                                                    status: DialogStatus.Default,
                                                    actions: [
                                                        {
                                                            label: "ANNULLA",
                                                            onClick: resetAlert,
                                                            inverse: true
                                                        },
                                                        {
                                                            label: "SI, ATTIVA",
                                                            onClick: () => changeAccountStatus(AccountStatus.Active),
                                                        }
                                                    ]
                                                })
                                            }}
                                        >
                                            <UnlockIcon />
                                            ATTIVA
                                        </Button>
                                    }

                                </div>
                            </div>
                            <div className={styles.rightColumn}>
                                <div className={styles.card}>
                                    <div className={styles.cardTitle}>{t("profile.editTitle")}</div>
                                    <div className={styles.inputs}>
                                        <div className={styles.row}>
                                            <TextInput value={student?.name} type="text" placeholder={capitalize(t('profile.name'))} onKeyUp={v => setName(v)}></TextInput>
                                            <TextInput value={student?.surname} type="text" placeholder={capitalize(t('profile.surname'))} onKeyUp={v => setSurname(v)}></TextInput>
                                        </div>
                                        <div className={styles.row}>
                                            <DropdownSelection
                                                defaultOption={employmentOptions.find(e => e.value === student?.employment)?.id}
                                                options={employmentOptions}
                                                appereance="transparent"
                                                placeholder="Occupazione"
                                                onSelect={(id) => setEmployment(employmentOptions.find(e => e.id === id)?.value)} />
                                            <DropdownSelection
                                                defaultOption={genders.find(e => e.value === student?.gender)?.id}
                                                options={genders}
                                                appereance="transparent"
                                                placeholder="Genere"
                                                onSelect={(id) => setGender(genders.find(g => g.id === id)?.value)}
                                            />
                                        </div>
                                        {["student", "studentWorker"].includes(employment) &&
                                            <div className={styles.row}>
                                                <DropdownSelection
                                                    search
                                                    placeholder="Università"
                                                    defaultOption={universities.findIndex(u => u.name === student?.university)}
                                                    options={universities.map((u, i) => { return { id: i, label: u.name } })}
                                                    appereance="transparent"
                                                    onSelect={(id) => setUniversity(universities[id]?.name ?? "")} />

                                            </div>
                                        }
                                        <div className={styles.row}>
                                            <TextInput value={student?.age} type="number" placeholder={capitalize(t('profile.age'))} onKeyUp={onAgeChange}></TextInput>
                                            <TextInput value={student?.city} type="text" placeholder={capitalize(t('profile.city'))} onKeyUp={v => setCity(v)} autocomplete="off" suggestions={comuni.map(c => `${c.nome} (${c.sigla})`)}></TextInput>
                                        </div>
                                        <Checkbox
                                            value={mandatorySurvey}
                                            label={"Questionario obbligatorio?"}
                                            onChange={v => setMandatorySurvey(v)}
                                        />
                                        <div className={styles.error}>
                                            {error && error.code === -1 &&
                                                <span className={typo.caption}>
                                                    {error.message}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <div style={{ alignSelf: "flex-end" }}>
                                        <Button
                                            status={savingStatus}
                                            accentColor={"var(--primary)"}
                                            style={{ padding: "0.8rem 1.5rem" }}
                                            disabled={hasChanges === false}
                                            onClick={saveChanges}
                                        >
                                            {t("profile.saveChanges").toUpperCase()}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <Card style={{ backgroundColor: 'var(--background-color)' }}>
                            <div className={styles.surveyCard}>
                                <div className={styles.row} style={{ justifyContent: 'space-between', padding: 0 }}>
                                    <div className={styles.column} style={{ padding: 0, gap: 0 }}>
                                        <div className={typo.headline}>{"QUESTIONARIO"}</div>
                                        <div className={typo.title} style={{ fontSize: '1.3rem', whiteSpace: 'nowrap' }}>Risultati</div>
                                    </div>
                                </div>

                                <div className={styles.surveyInner}>
                                    {width >= 1030 &&
                                        <div className={[styles.column, styles.surveyData].join(' ')}>

                                            {radarDatasets.map(dataset => {
                                                return dataset.values.map((value, index) => ({
                                                    value,
                                                    label: dataset.labels[index],
                                                    color: dataset.colors[index]
                                                }));
                                            }).flat().slice(0, 3).map(data => {
                                                return (
                                                    <div className={styles.chartCell} key={data.label}>
                                                        <div className={styles.chartRow}>
                                                            <div className={styles.chartColumn}>
                                                                <div className={styles.chartColor} style={{ backgroundColor: data.color }}></div>
                                                            </div>
                                                            <div className={styles.chartColumn}>
                                                                <div className={styles.chartDataTitle}>{data.value}</div>
                                                                <div className={styles.chartDataSubitle} style={{ color: data.color }}>{data.label}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                    <div className={[styles.column, styles.surveyChart].join(' ')}>
                                        <RadarChart data={radarDatasets} options={radarOptions}></RadarChart>
                                    </div>

                                    <div className={[styles.column, styles.surveyData].join(' ')}>
                                        {radarDatasets.map(dataset => {
                                            return dataset.values.map((value, index) => ({
                                                value,
                                                label: dataset.labels[index],
                                                color: dataset.colors[index]
                                            }));
                                        }).flat().slice(width >= 1030 ? 3 : 0).map(data => {
                                            return (
                                                <div className={styles.chartCell} key={data.label}>
                                                    <div className={styles.chartRow}>
                                                        <div className={styles.chartColumn}>
                                                            <div className={styles.chartColor} style={{ backgroundColor: data.color }}></div>
                                                        </div>
                                                        <div className={styles.chartColumn}>
                                                            <div className={styles.chartDataTitle}>{data.value}</div>
                                                            <div className={styles.chartDataSubitle} style={{ color: data.color }}>{data.label}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                </div>
                            </div>
                        </Card>


                        <Card style={{ backgroundColor: 'var(--background-color)' }}>
                            <div className={styles.preferenceCard}>
                                <div className={styles.row} style={{ justifyContent: 'space-between', padding: 0 }}>
                                    <div className={styles.column} style={{ padding: 0, gap: 0 }}>
                                        <div className={typo.headline}>PREFERENZE</div>
                                    </div>
                                </div>
                                <div className={styles.preferenceInner}>
                                    <div className={styles.preference}>
                                        <div className={styles.preferenceLabel}>
                                            Azienda dei tuoi sogni
                                        </div>
                                        <div className={styles.preferenceValue}>
                                            <CityIcon /> IBM
                                        </div>
                                    </div>
                                    <div className={styles.preference}>
                                        <div className={styles.preferenceLabel}>
                                            RAL dei tuoi sogni
                                        </div>
                                        <div className={styles.preferenceValue}>
                                            <MoneybagIcon />40.000 - 60.000€
                                        </div>
                                    </div>
                                    <div className={styles.preference}>
                                        <div className={styles.preferenceLabel}>
                                            Worktime dei tuoi sogni
                                        </div>
                                        <div className={styles.preferenceValue}>
                                            <ClockIcon /> 8h
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Card> */}

                        <Card style={{ backgroundColor: 'var(--background-color)' }}>
                            <div className={styles.coursesCard}>
                                <div className={styles.row} style={{ justifyContent: 'space-between', padding: 0 }}>
                                    <div className={styles.column} style={{ padding: 0, gap: 0 }}>
                                        <div className={typo.headline}>CORSI</div>
                                    </div>
                                    <div className={styles.column} style={{ padding: 0, gap: 0, display: "flex", alignItems: "flex-end" }}>
                                        <Button
                                            onClick={() => {
                                                if (!addCourseMode) {
                                                    setAddCourseMode(true)
                                                    setEditEditionMode(false)
                                                    setEditions([])
                                                    setSelectedCourse(null)
                                                    setSelectedEdition(null)
                                                } else {
                                                    setAddCourseMode(false)
                                                }
                                            }}
                                            appearance="text"
                                            disabled={loadingCourses}
                                        >
                                            {!addCourseMode ? "Nuova iscrizione" : "Annulla"}
                                        </Button>
                                    </div>
                                </div>
                                {
                                    courses.length > 0 &&
                                    <Each of={courses} render={(course) => {
                                        return (
                                            <div className={styles.course} onClick={() => navigate(`/courses/${course.id}`)}>
                                                <img src={course.thumbnail} className={styles.thumbnail} alt="" />
                                                <div className={styles.info}>
                                                    <CourseBadge type={course.type} />
                                                    <div className={styles.title}>{course.edition ? `${course.name} - ${course.edition.name}` : course.name}</div>
                                                    <div className={styles.description}>{course.description}</div>
                                                </div>
                                                <MeatBallsMenu actions={
                                                    [
                                                        {
                                                            id: 0,
                                                            label: "Modifica",
                                                            icon: PencilIcon,
                                                            style: { color: "var(--primary)" },
                                                            onClick: () => navigate(`/courses/${course.id}/editor`)
                                                        },
                                                        {
                                                            id: 1,
                                                            label: course.edition ? "Cambia edizione" : "Aggiungi edizione",
                                                            icon: course.edition ? RepeatIcon : AddIcon,
                                                            style: { color: "var(--sf-yellow)" },
                                                            onClick: () => {
                                                                setSelectedCourse(course)
                                                                setEditEditionMode(true)
                                                                setAddCourseMode(false)
                                                            }
                                                        },
                                                        {
                                                            id: 2,
                                                            label: "Disiscrivi",
                                                            icon: DeleteIcon,
                                                            style: { color: "var(--sf-red)" },
                                                            onClick: () => {
                                                                setAlert({
                                                                    open: true,
                                                                    title: "Elimina iscrizione",
                                                                    message: `Stai per eliminare l'iscrizione di ${student.name} ${student.surname} al corso "${course.name}${course.edition ? ` - ${course.edition.name}".` : '".'} Sei sicuro di voler procedere?`,
                                                                    actions:
                                                                        [
                                                                            {
                                                                                label: "ANNULLA",
                                                                                onClick: resetAlert,
                                                                                inverse: true
                                                                            },
                                                                            {
                                                                                label: "SI, ELIMINA",
                                                                                onClick: () => removeCourse(course.id, course.edition?.id ?? null),
                                                                            }
                                                                        ]
                                                                })

                                                            }
                                                        }
                                                    ]
                                                } />
                                            </div>
                                        )
                                    }} />
                                }
                                {
                                    courses.length === 0 &&
                                    <div className={styles.noData}> Nessun corso acquistato </div>
                                }
                                {
                                    addCourseMode &&
                                    <>
                                        <div className={styles.spacer} />
                                        <div ref={ref} className={styles.column}>
                                            <div className={typo.subheadline}>Corsi disponibili</div>
                                            <Each
                                                of={availableCourses}
                                                render={(course) => {
                                                    return (
                                                        <div className={selectedCourse?.id === course.id ? `${styles.course} ${styles.selected}` : styles.course} onClick={() => {
                                                            if (selectedCourse && selectedCourse.id === course.id) {
                                                                setSelectedCourse(null)
                                                                setEditions([])
                                                                setSelectedEdition(null)
                                                            } else {
                                                                setSelectedCourse(course)
                                                            }
                                                        }}>
                                                            <img src={course.thumbnail} className={styles.thumbnail} alt="" />
                                                            <div className={styles.info}>
                                                                <CourseBadge type={course.type} />
                                                                <div className={styles.title}>{course.edition ? `${course.name} - ${course.edition.name}` : course.name}</div>
                                                                <div className={styles.description}>{course.description}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                }} />
                                            {
                                                selectedCourse !== null &&
                                                <div className={typo.subheadline}>Edizioni di {selectedCourse.name}</div>
                                            }
                                            {
                                                loadingEditions &&
                                                <div style={{ height: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <Loader />
                                                </div>
                                            }
                                            {
                                                selectedCourse !== null && !loadingEditions && editions.length === 0 &&
                                                <div className={styles.noData}>Nessuna edizione disponibile</div>
                                            }
                                            {
                                                !loadingEditions && editions.length > 0 &&
                                                <>
                                                    <div className={styles.editions}>
                                                        <Each
                                                            of={editions}
                                                            render={(edition) => {
                                                                return (
                                                                    <div
                                                                        className={selectedEdition?.id === edition.id ? `${styles.edition} ${styles.selected}` : styles.edition}
                                                                        onClick={() => {
                                                                            if (selectedEdition && selectedEdition.id === edition.id) {
                                                                                setSelectedEdition(null)
                                                                            } else {
                                                                                setSelectedEdition(edition)
                                                                            }
                                                                        }}
                                                                    >
                                                                        <div className={styles.editionName}>{edition.id} - {edition.name}</div>
                                                                        <div className={styles.editionInfo}>
                                                                            <div className={styles.editionInfoRow}>
                                                                                <CalendarIcon />
                                                                                {getEditionStart(edition) !== undefined ? formatDateV2(getEditionStart(edition), dateOptions) : ''} - {getEditionEnd(edition) !== undefined ? formatDateV2(getEditionEnd(edition), dateOptions) : ''}
                                                                            </div>
                                                                            <div className={styles.editionInfoRow}>
                                                                                <BookIcon />
                                                                                {edition.modules.length}
                                                                                {edition.modules.length === 1 ? ' modulo' : ' moduli'}
                                                                            </div>
                                                                            <div className={styles.editionInfoRow}>
                                                                                <LessonIcon />
                                                                                {flatLesson(edition).length}
                                                                                {flatLesson(edition).length === 1 ? ' lezione' : ' lezioni'}
                                                                            </div>
                                                                            <div className={styles.editionInfoRow}>
                                                                                <StudentsIcon />
                                                                                {edition.students}
                                                                                {edition.students === 1 ? ' studente' : ' studenti'}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }} />
                                                    </div>
                                                </>
                                            }
                                            {
                                                selectedCourse != null &&
                                                <div style={{ alignSelf: "flex-end" }}>
                                                    <Button
                                                        accentColor={"var(--primary)"}
                                                        style={{ padding: "0.8rem 1.5rem" }}
                                                        onClick={() => {
                                                            setAlert({
                                                                open: true,
                                                                title: "Nuova iscrizione",
                                                                message: `Stai per iscrivere ${student.name} ${student.surname} al corso "${selectedCourse.name}${selectedEdition ? ` - ${selectedEdition.name}".` : '".'} Sei sicuro di voler procedere?`,
                                                                actions:
                                                                    [
                                                                        {
                                                                            label: "ANNULLA",
                                                                            onClick: resetAlert,
                                                                            inverse: true
                                                                        },
                                                                        {
                                                                            label: "SI, ISCRIVI",
                                                                            onClick: () => subscribe(selectedCourse.id, selectedEdition?.id),
                                                                        }
                                                                    ]
                                                            })

                                                        }}
                                                    >
                                                        {selectedEdition !== null ? "ISCRIVI ALL'EDIZIONE" : "ISCRIVI AL CORSO"}
                                                    </Button>
                                                </div>
                                            }
                                        </div>
                                    </>
                                }
                                {
                                    editEditionMode &&
                                    <>
                                        <div className={styles.spacer} />
                                        <div ref={ref} className={styles.column}>
                                            {
                                                selectedCourse !== null &&
                                                <div className={typo.subheadline}>Edizioni di {selectedCourse.name}</div>
                                            }
                                            {
                                                loadingEditions &&
                                                <div style={{ height: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <Loader />
                                                </div>
                                            }
                                            {
                                                selectedCourse !== null && !loadingEditions && editions.length === 0 &&
                                                <div className={styles.noData}>Nessuna edizione disponibile</div>
                                            }
                                            {
                                                !loadingEditions && editions.length > 0 &&
                                                <>
                                                    <div className={styles.editions}>
                                                        <Each
                                                            of={editions}
                                                            render={(edition) => {
                                                                return (
                                                                    <div
                                                                        className={selectedEdition?.id === edition.id ? `${styles.edition} ${styles.selected}` : styles.edition}
                                                                        onClick={() => {
                                                                            if (selectedEdition && selectedEdition.id === edition.id) {
                                                                                setSelectedEdition(null)
                                                                            } else {
                                                                                setSelectedEdition(edition)
                                                                            }
                                                                        }}
                                                                    >
                                                                        <div className={styles.editionName}>{edition.id} - {edition.name}</div>
                                                                        <div className={styles.editionInfo}>
                                                                            <div className={styles.editionInfoRow}>
                                                                                <CalendarIcon />
                                                                                {getEditionStart(edition) !== undefined ? formatDateV2(getEditionStart(edition), dateOptions) : ''} - {getEditionEnd(edition) !== undefined ? formatDateV2(getEditionEnd(edition), dateOptions) : ''}
                                                                            </div>
                                                                            <div className={styles.editionInfoRow}>
                                                                                <BookIcon />
                                                                                {edition.modules.length}
                                                                                {edition.modules.length === 1 ? ' modulo' : ' moduli'}
                                                                            </div>
                                                                            <div className={styles.editionInfoRow}>
                                                                                <LessonIcon />
                                                                                {flatLesson(edition).length}
                                                                                {flatLesson(edition).length === 1 ? ' lezione' : ' lezioni'}
                                                                            </div>
                                                                            <div className={styles.editionInfoRow}>
                                                                                <StudentsIcon />
                                                                                {edition.students}
                                                                                {edition.students === 1 ? ' studente' : ' studenti'}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }} />
                                                    </div>
                                                </>
                                            }
                                            {
                                                selectedCourse != null &&
                                                <div style={{ alignSelf: "flex-end" }}>
                                                    <Button
                                                        disabled={selectedEdition === null}
                                                        accentColor={"var(--primary)"}
                                                        style={{ padding: "0.8rem 1.5rem" }}
                                                        onClick={() => {
                                                            setAlert({
                                                                open: true,
                                                                title: "Nuova iscrizione",
                                                                message: `Stai per iscrivere ${student.name} ${student.surname} al corso "${selectedCourse.name}${selectedEdition ? ` - ${selectedEdition.name}".` : '".'} Sei sicuro di voler procedere?`,
                                                                actions:
                                                                    [
                                                                        {
                                                                            label: "ANNULLA",
                                                                            onClick: resetAlert,
                                                                            inverse: true
                                                                        },
                                                                        {
                                                                            label: "SI, ISCRIVI",
                                                                            onClick: () => subscribe(selectedCourse.id, selectedEdition.id),
                                                                        }
                                                                    ]
                                                            })

                                                        }}
                                                    >
                                                        {"ISCRIVI ALL'EDIZIONE"}
                                                    </Button>
                                                </div>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </Card>
                        <Dialog
                            open={dialogOpen}
                            style={{
                                width: "30vw",
                                maxWidth: "512px"
                            }}
                            onClose={() => {
                                setDialogOpen(false)
                                setCropMode(false)
                            }}
                            title={"Immagine del profilo"}>
                            <div className={styles.dialog}>
                                {!cropMode &&
                                    <Dropzone
                                        onDrop={(files) => {
                                            if (files[0]) {
                                                setImgFile(files[0])
                                                setCropMode(true)
                                            }
                                        }}
                                        accept={{
                                            'image/jpeg': [],
                                            'image/png': [],
                                            'image/heic': []
                                        }}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section style={{ display: 'flex', width: '100%' }}>
                                                <div {...getRootProps()} style={{ display: 'flex', width: '100%' }}>
                                                    <input {...getInputProps()} ref={dropzoneRef} />
                                                    <div className={styles.editPictureDropzone}>
                                                        <InsertImageIllustration></InsertImageIllustration>
                                                        <div className={styles.editPictureLabel}>
                                                            {t('profile.dropImage')}
                                                        </div>
                                                        <Button
                                                            style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                                            accentColor={"var(--tertiary)"}
                                                            onClick={() => dropzoneRef.current?.click()}
                                                        >
                                                            {t('profile.chooseImage').toUpperCase()}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                }
                                {cropMode &&
                                    <>
                                        <ImageCropper
                                            src={URL.createObjectURL(imgFile)}
                                            onSave={(image) => {
                                                setProfilePic(image)
                                                setCropMode(false)
                                                setDialogOpen(false)
                                            }}
                                            onCancel={() => { setCropMode(false) }}
                                            compression={{ quality: 0.7, maxWidth: 300, maxHeight: 300 }}
                                        >
                                        </ImageCropper>
                                    </>
                                }
                            </div>
                        </Dialog>

                        <AlertDialog
                            open={alert.open}
                            title={alert.title}
                            text={alert.message}
                            onClose={alert.onClose ?? resetAlert}
                            actions={alert.actions}
                            status={alert.status}
                        />
                    </div>
                </div>
            </div >
        </HeaderFooterLayout >
    )
}

export default Student
