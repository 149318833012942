import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
  headingsPlugin,
  InsertTable,
  linkDialogPlugin,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  quotePlugin,
  Separator,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  UndoRedo
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { useEffect, useRef, useState } from 'react';
import Dropzone from "react-dropzone";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../api';
import { ReactComponent as InsertDocumentIllustration } from "../assets/images/illustrations/il-insert-document.svg";
import { ButtonStatus } from '../common/constants';
import Back from '../components/Back';
import Button from "../components/Button";
import ArticleCard from '../components/cards/ArticleCard';
import Card from '../components/cards/Card';
import HeaderFooterLayout from '../components/layouts/HeaderFooterLayout';
import TagSelector from "../components/TagSelector";
import TextInput from "../components/TextInput";
import typo from "../typography.module.css";
import styles from "./ArticleEditor.module.css";

const ArticleEditor = ({ }) => {
  const { article_id } = useParams()
  const navigate = useNavigate()
  const [article, setArticle] = useState(null)
  const [name, setName] = useState("")
  const [abstract, setAbstract] = useState("")
  const [readingTime, setReadingTime] = useState(null)
  const [tags, setTags] = useState([])
  const [filteredTags, setFilteredTags] = useState([])

  const [thumbnail, setThumbnail] = useState(null)
  const [thumbnailFile, setThumbnailFile] = useState(null)
  const [thumbnailPreview, setThumbnailPreview] = useState(null)
  const [url, setUrl] = useState("")

  const [publishing, setPublishing] = useState(null)

  const dropzoneRef = useRef(null)
  const mdRef = useRef(null)

  useEffect(() => {
    const getArticle = async () => {
      try {
        const article = await api.get(`/admin/articles/${article_id}`)
        setArticle(article)
      }
      catch (e) {
        console.error(e)
      }
    }

    if (article_id !== "create") {
      getArticle()
    }
  }, []);


  useEffect(() => {
    const getTags = async () => {
      try {
        const tags = await api.get("/admin/tags?type=general")
        setFilteredTags(tags)
      }
      catch (e) {
        console.error(e)
      }
    }

    getTags()
  }, [])

  useEffect(() => {
    if (article) {
      setName(article.name)
      setAbstract(article.abstract)
      setReadingTime(article.reading_time)
      mdRef.current?.setMarkdown(article.abstract)
      setUrl(article.url)
      setThumbnail(article.thumbnail)
      setThumbnailPreview(article.thumbnail)
      setTags(article.tags)
    }

  }, [article])

  useEffect(() => {
    if (thumbnailFile) {
      setThumbnailPreview(URL.createObjectURL(thumbnailFile))
    } else {
      setThumbnailPreview(null)
    }
  }, [thumbnailFile])

  useEffect(() => {
    if (thumbnail) {
      setThumbnailPreview(thumbnail)
    } else {
      setThumbnailPreview(null)
    }
  }, [thumbnail])

  const saveArticle = async () => {
    try {
      setPublishing(ButtonStatus.Loading)
      const form = new FormData()
      form.append("name", name)
      form.append("abstract", abstract)
      form.append("url", url)
      if (readingTime) {
        form.append("reading_time", readingTime)
      }
      if (tags && tags.length > 0) {
        form.append("tags", JSON.stringify(tags.map(t => t.id)))
      }
      if (thumbnailFile) {
        form.append("thumbnailFile", thumbnailFile)
      } else if (thumbnail) {
        form.append("thumbnail", thumbnail)
      }

      const res = article_id === "create" ?
        await api.post("/admin/articles", form, { headers: { "Content-Type": "multipart/form-data" } }) :
        await api.put(`/admin/articles/${article_id}`, form, { headers: { "Content-Type": "multipart/form-data" } })

      setPublishing(ButtonStatus.Success)

      if (article_id === "create") {
        navigate(`/articles`)
      } else {
        setArticle(res)
      }
    } catch (e) {
      setPublishing(ButtonStatus.Error)

      console.error(e)
    }
  }

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>Editor Articolo</title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <Back onClick={() => navigate(-1)} />
            <div className={styles.header}>
              <div className={typo.title} style={{ display: 'flex', justifyContent: 'space-between' }}>
                {article_id === "create" ? "Aggiungi articolo" : "Modifica articolo"}
              </div>
            </div>
            <ArticleCard
              article={{
                name,
                abstract,
                tags,
                thumbnail: thumbnailPreview,
                url,
                reading_time: readingTime
              }}
              interactive={false}
            />
            <Card>
              <div className={styles.content}>
                <div className={styles.top}>
                  <div className={styles.left}>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>TITOLO</div>
                      <TextInput
                        type="text"
                        value={name}
                        placeholder={"Titolo"}
                        onKeyUp={v => setName(v)}
                      />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>TEMPO DI LETTURA</div>
                      <TextInput
                        type="number"
                        value={readingTime}
                        placeholder={"Tempo di lettura"}
                        onKeyUp={(v) => setReadingTime(v)}
                      />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>TAG</div>
                      <TagSelector
                        selected={tags}
                        options={filteredTags}
                        placeholder={(tags.length === 0 && "Seleziona un tag") || ""}
                        style={{ fontSize: "1rem" }}
                        onAdd={(item) => {
                          setTags(t => {
                            t.push(item)
                            return [...t]
                          })
                        }}
                        onRemove={(item) => {
                          setTags(t => {
                            t = t.filter((tt) => tt.id !== item.id)
                            return [...t]
                          })
                        }}
                      />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>{"URL THUMBNAIL"}</div>
                      <TextInput
                        type="text"
                        value={thumbnail}
                        placeholder={"Url thumbnail"}
                        onKeyUp={(v) => setThumbnail(v)}
                      />
                    </div>
                    <div className={styles.labeled}>
                      <div className={typo.caption}>{"URL ARTICOLO"}</div>
                      <TextInput
                        type="text"
                        value={url}
                        placeholder={"Url articolo"}
                        onKeyUp={(v) => setUrl(v)}
                      />
                    </div>
                  </div>
                  <div className={styles.right}>
                    <div className={styles.mediaContainer}>
                      {
                        !thumbnailFile && !thumbnail &&
                        <Dropzone
                          accept={{
                            'image/jpeg': [],
                            'image/png': [],
                            'image/webp': [],
                            'image/heic': [],
                            'image/jfif': [],
                          }}
                          multiple={false}
                          onDrop={(files) => {
                            setThumbnailFile(files[0])
                          }}>
                          {({ getRootProps, getInputProps }) => (
                            <section style={{ display: 'flex', height: "100%", width: '100%', padding: 0, margin: 0 }}>
                              <div {...getRootProps()} style={{ display: 'flex', width: '100%' }} ref={dropzoneRef} >
                                <input {...getInputProps()} />
                                <div className={styles.dropzone}>
                                  <InsertDocumentIllustration />
                                  <div className={styles.dropzoneLabel}>
                                    Trascina o seleziona l'immagine
                                  </div>
                                  <Button
                                    style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                    accentColor={"var(--tertiary)"}
                                    onClick={() => dropzoneRef.current?.click()}
                                  >
                                    SELEZIONA
                                  </Button>
                                </div>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      }

                      {
                        thumbnailPreview &&
                        <div className={styles.thumbnailPreview}>
                          <img src={thumbnailPreview} alt="thumbnail" />
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className={styles.editor}>
                  <MDXEditor
                    ref={mdRef}
                    contentEditableClassName={styles.markdownEditor}
                    markdown={abstract}
                    placeholder="Abstract"
                    onChange={(value) => { setAbstract(value) }}
                    plugins={[
                      headingsPlugin(),
                      listsPlugin(),
                      quotePlugin(),
                      thematicBreakPlugin(),
                      linkDialogPlugin(),
                      diffSourcePlugin(),
                      tablePlugin(),
                      toolbarPlugin({
                        toolbarContents: () => (
                          <>
                            {' '}
                            <UndoRedo />
                            <Separator />
                            <BoldItalicUnderlineToggles />
                            <Separator />
                            <ListsToggle />
                            <CreateLink />
                            <InsertTable />
                            <Separator />
                            <BlockTypeSelect />
                            <DiffSourceToggleWrapper options={["rich-text", "source"]} />
                          </>
                        )
                      })
                    ]}
                  />
                </div>
                <div className={styles.action}>
                  <Button
                    status={publishing}
                    disabled={!name || !abstract || (!thumbnail && !thumbnailFile) || !url}
                    style={{ padding: "0.75rem 2rem" }}
                    onClick={saveArticle}
                  >
                    {article_id === "create" ? "PUBBLICA" : "SALVA"}
                  </Button>
                </div>
              </div>

            </Card>
          </div>
        </div>
      </div>
    </HeaderFooterLayout >
  )
}

export default ArticleEditor
